/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Modal
-------------------------------- */
/*
This is the container for modals, which places them above the other content and applies a light overlay to the content
*/

// % protected region % [Change modal styles here] on begin
.modal-container {
	background-color: rgba(black, 0.15);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	overflow: auto;
	padding: 100px 0;
	z-index: $z-index-modal;
}

$shadow-color: rgba(black, 0.1);

/*The container for the modal content proper*/
.modal-content {
	font-family: $body-font-family;
	position: relative;
	border: none;
	background: $background;
	color: get-contrasting-text-color($background);
	border-radius: $space-sm;
	outline: none;
	padding: 40px;
	height: fit-content;
	overflow: visible;
	margin: auto;
	z-index: $z-index-modal;
	width: 432px;
	box-shadow: 0 10px 30px 5px $shadow-color, 0 0 10px 0 $shadow-color;
	font-size: 16px;
	font-family: $body-font-family;

	.modal--close {
		display: none;
	}

	@include breakpoint(mobile) {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		// height: convert-rem(500px);
		width: 95%;
		margin: auto;
		top: 72px;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

.modal {
	&__header {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		.btn {
			padding: 0;
		}

		h4 {
			margin: 0;
		}
	}

	&__message {
		margin: 24px 0;
		font-size: 16px;

		p {
			margin: 0;
		}
	}

	.input-group__textarea {
		textarea {
			width: 100%;
			max-width: unset;
		}
	}

	&__actions {
		margin-top: $space-sm;
		display: flex;
		justify-content: space-between;
		gap: 16px;

		.modal {
			&--cancel,
			&--confirm {
				height: 48px;
			}

			&--cancel {
				color: $color-disabled-light;
			}
		}
	}

	&__confirm-actions {
		.btn {
			width: 100%;
		}

		.modal--cancel {
			color: var(--color-primary);
		}
	}

	&__alert-actions {
		.btn {
			width: 100%;
		}

		.modal--cancel {
			color: $white;
		}
	}
}

// % protected region % [Change modal styles here] end

// % protected region % [Add additional modal styles here] on begin
.create-study-plan-modal {
	width: 800px;

	.modal__actions {
		justify-content: flex-end;
	}

	.input-group__dropdown {
		.dropdown__container > .text {
			width: calc(100% - 32px);
		}

		.program-version-option {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	.commencing-year-wrapper {
		display: flex;
		flex-direction: column;
		margin-top: 16px;

		.commencing-year-label {
			font-size: 13px;
			color: $grey-5;
			font-weight: $text-light;
			margin: 0;
		}

		#commencing-year-checkbox-group {
			margin: 4px 0;

			input:focus {
				outline: 3px solid var(--color-primary),
			}
		}

		.input-group-wrapper__checkbox {
			flex-direction: row;
			gap: 12px;

			.input-group__checkbox {
				min-width: fit-content;
			}
		}

		.input-group__dropdown {
			margin: 0;
		}
	}
}

.information-modal {
	@media only screen and (min-width: 799px) {
		width: 667px;
	}

	h6 {
		font-weight: 400;
		margin: 0;
	}

	.information-modal__section {
		margin-bottom: 24px;

		h6 {
			color: $color-medium-emphasis;
			margin-bottom: 6px;
		}
	}

	@media only screen and (max-width: 800px) {
		.modal__message {
			margin: 0;
		}

		.modal__actions {
			margin: 0;
			justify-content: center;
		}
	}
}

.selection-notice-modal {
	background-color: var(--color-warning-light);
	max-width: 432px;

	.modal__header > h4,
	.modal__message > p,
	.modal__actions > button {
		color: var(--color-warning-dark);
	}

	.modal__actions > button {
		@include inner-box-shadow(
			convert-rem(0px),
			convert-rem(0px),
			convert-rem(0px),
			convert-rem(1px),
			var(--color-warning-dark)
		);

		&:hover {
			background-color: var(--color-warning-dark);
		}
	}
}

.dangerous-action-modal {
	.modal__message {
		.danger-text {
			color: var(--color-warning-dark);
		}
	}

	.modal__actions {
		.modal--confirm {
			background-color: var(--color-warning);
		}
	}
}
// % protected region % [Add additional modal styles here] end