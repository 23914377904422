.course-set-list-page {
	padding: 40px 64px;

	&__actions {
		display: grid;
		grid-template-rows: 1fr;
		grid-template-columns: 1fr min-content;
		align-items: center;
		gap: 40px;
		padding: 16px 0;

		.input-group {
			margin: 0;

			input {
				margin: 0;
			}
		}

		.search-input-wrapper.input-group {
			margin: 0 auto;
			width: 100%;

			.icon-search {
				position: absolute;
				z-index: 1;
				top: 1.1rem;
				left: 1rem;
				color: $color-medium-emphasis;
			}

			input {
				padding-left: 3rem;
				margin: 0px;
			}
		}
	}

	.course-set-collection .collection__list table tbody .collection__item .list__items--actions .btn-group {
		justify-content: end;

		& > .btn:before,
		& > .btn:after {
			color: $color-medium-emphasis;
			font-size: 32px;
		}
	}
}

.crud--CourseSetEntity {
	height: 100%;

	.add-edit-course-set-page {
		height: 100%;
		overflow: hidden;
		display: grid;
		grid-template-areas: 
		"page-header"
		"page-main"
		"page-footer";
		grid-template-columns: 1fr;
		grid-template-rows: min-content minmax(0, auto) min-content;
		background: var(--color-white, #FFF);

		.page-header {
			grid-area: page-header;
			padding: 16px;
			display: flex;
			justify-content: end;
			background: var(--color-white, #FFF);
			/* Shadow - down */
			box-shadow: 0 4px 24px 0 rgba(124, 124, 124, 0.04);

			.exit-btn {
				padding: 6px 16px;
			}
		}

		.page-main {
			grid-area: page-main;
			overflow: auto;
			padding: 60px 120px;

			.title {
				display: flex;
				justify-content: space-between;
			}

			.input-section {
				max-width: 600px;

				h4 {
					padding: 12px 0;
					margin: 0;
					border-bottom: 1px solid $background-border;
				}

				.input-group {
					margin-bottom: 16px;

					input[disabled] {
						color: black;
					}
				}

				.add-course-btn {
					margin-top: 16px;
					width: 100%;
				}

				.course-list {
					display: grid;
					gap: 8px;
					margin-top: 16px;

					.horizontal-input-group {
						display: flex;
						gap: 8px;
						justify-content: space-between;

						.input-group {
							width: 100%;
							margin: 0;

							.dropdown {
								margin: 0;

								.icon {
									top: 0;
									right: 0;
								}
							}
						}
					}
				}
			}
		}

		.page-footer {
			grid-area: page-footer;
			padding: 16px 40px;
			display: flex;
			justify-content: end;
			gap: 16px;
			background: var(--color-white, #FFF);

			/* Shadow - up */
			box-shadow: 0px -2px 6px 0px rgba(124, 124, 124, 0.06);
		}
	}
}