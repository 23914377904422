/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Collection - Mobile styling
-------------------------------- */
/*
Mobile responsive - ideal to display grid when on mobile devices
*/

// % protected region % [Change collection mobile styles here] on begin
@include media(xs, sm) {
	padding: 0;

	/******* Top section of collection - Search bar, filter buttons, grid buttons *******/
	.collection__menu {
		flex-direction: column;

		.search,
		.search input[type=text] {
			width: 100%;
		}

		//Action buttons
		.collection__actions {
			display: flex;
			width: 100%;
			margin-top: $space-xs;

			.btn {
				&:first-of-type {
					margin-left: 0;
				}
			}
		}
	}

	/******* Collection filter ********/
	.collection__filters {
		.collection-filter-form__actions {
			flex-wrap: wrap;
		}
	}

	/******* Collection grid ********/
	.collection__grid {
		justify-content: center;
	}

	//pagination styles for collections if necessary
	.collection__pagination {
		li:first-child {
			flex-grow: 1;
		}
	}
}
// % protected region % [Change collection mobile styles here] end

// % protected region % [Add additional collection mobile styles here] off begin
// % protected region % [Add additional collection mobile styles here] end