.info-icon-button {
	border: none;
	padding: 0;
	line-height: 1;
	border-radius: 50%;
	display: inline-block;
	color: $color-medium-emphasis;
	background-color: $white;
	cursor: pointer;
	height: 24px;
	width: 24px;

	.screen-reader-only{
		position: absolute;
		width: 1px;
		height: 1px;
		margin: -1px;
		padding: 0;
		overflow: hidden;
		clip: rect(0, 0, 0, 0);
	}

	span{
		display: none
	}
	
	i:before {
		text-indent: 0;
		letter-spacing: 0;
		word-spacing: 0;
	}

	&.error {
		i:before {
			color: var(--color-warning);
		}
	}
}

.info-icon-button:focus-visible {
	outline: 3px solid var(--color-primary);
}