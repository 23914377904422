/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */

// % protected region % [Configure combobox styles here] on begin
.input-group__dropdown {
	input {
		width: 100%;
	}

	.ui.selection.dropdown {
		color: #9EA6A9;
		line-height: 22px;
		background-color: #F2F2F2;

		&.filled {
			min-height: 54px;
			background-color: $background;
			border: 1px solid $background-border;
		}

		&:not(.filled) {
			border: 1px solid $grey-0;
		}

		> .text:not(.default) {
			color: get-contrasting-text-color($background);
		}

		&.disabled {
			background: $grey-0;
			border: 1px solid $grey-0;
			opacity: 0.8;
			color: transparentize(get-contrasting-text-color($grey-0), .2);

			&:hover {
				border-color: none;
			}

			.search, .dropdown {
				opacity: 1;
			}
		}

		.menu {
			margin: 0 -2px;
			min-width: calc(100% + 3px);
			border-top-right-radius: 0;
			border-top-left-radius: 0;

			> .item {
				color: get-contrasting-text-color($background) !important;
				border: none;
				padding: $space-xs $space-sm !important;
			}
		}

		&:has(.default) {
			background: #F2F2F2;
			border: none
		}

		.default {
			.text {
				color: #9EA6A9;
			}
		}
	}

	.dropdown__container {
		grid-area: input;
		background-color: #F2F2F2;
		line-height: 1;
		font-size: $input-font-size;
		vertical-align: top;
		border-radius: $input-radius;
		color: get-contrasting-text-color($background);
		font-weight: $text-regular;

		input {
			margin-top: 0.25rem;
		}

		.dropdown.icon {
			color: $color-disabled;
			line-height: 22px;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;

			&:before {
				content: "\e5cf";
				font-family: $icon-custom-name;
				font-size: 24px;
				vertical-align: text-top;
			}
		}

		.ui.selection.active.dropdown {
			&:hover {
				.menu {
					border-color: get-contrasting-text-color($background) !important;
				}
			}
		}

		.ui.selection.dropdown .menu, .ui.selection.active.dropdown .menu {
			background-color: $grey-2;
			border-radius: 0 !important;

			* {
				color: get-contrasting-text-color($background);
			}
		}

		&.active, &.active:hover, &:hover, &:focus, .menu {
			@include transform-timing($animation-time);
			border-radius: $input-radius;
		}

		&.single {
			margin: 4px 0 0 0;

			> .default.text {
				color: #9EA6A9;
				font-weight: $text-light;
				opacity: 1;
			}
		}

		&.multiple {
			margin: $space-xxxs 0 0 0;

			> .default.text {
				line-height: 22px;
				color: #9EA6A9;
				font-weight: $text-light;
				opacity: 1;
			}

			> .label {
				margin-right: 8px;
				border: none;
				text-decoration: none;
				background-color: rgb(242, 242, 242);
				color: #161616;
				padding: 4px 8px;
				border-radius: 8px;
				font-size: 14.5px;
				font-style: normal;
				font-weight: 400;
				line-height: 22px;

				> i.icon.delete {
					opacity: 1;
					margin-left: 4px;

					&:before {
						color: #161616;
						content: "\e5cd";
						font-family: $icon-custom-name;
						vertical-align: sub;
						font-size: 16px;
					}
				}
			}

			&:focus {
				outline: none;
			}
		}

		& > div {
			padding: 0;
		}

	}
	
	.option-label {
		color: 'red'
	}
}
// % protected region % [Configure combobox styles here] end