.reset-password-page {
	display: grid;
	grid-template-areas: "form";
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	padding: 16px;

	.institute-logo {
		max-width: 110px;
		margin-bottom: 24px;
	}

	h4, p {
		margin: 0 0 8px;
	}

	p {
		color: $grey-5;
	}

	.reset-password {
		grid-area: form;

		@include media(sm) {
			align-self: start;
		}

		@include media(xsm) {
			width: 100%;
			max-width: 100%;
		}
	}

	.input-group {
		margin-bottom: 16px;
	}

	.reset-pwd-buttons,
	.confirm-pwd-buttons {
		margin: 0;
	}
}