.steps {
	user-select: none;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: $space-xs;
	overflow-y: scroll;

	&::-webkit-scrollbar {
		display: none;
	}

	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */

	@include media(sm) {
		padding: 16px;
	}

	.step-label {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		gap: 12px;
		min-width: max-content;
		cursor: pointer;
		padding: 8px 12px;
		border-radius: 8px;

		&:hover {
			background-color: $color-secondary-bg;
		}

		p {
			margin: 0;
			font-weight: 300;
		}

		.step-number {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;

			min-width: 30px;
			min-height: 30px;
			border-radius: 8px;
			font-size: 1rem;
		}

		&.complete {
			.step-number {
				border: 1px solid var(--color-primary);
				color: $grey-5;
			}
		}

		&.current {
			font-weight: $text-medium;

			p {
				font-weight: $text-medium;
			}

			.step-number {
				background-color: var(--color-primary);
				color: white;
			}
		}

		&.incomplete {
			color: $color-medium-emphasis;

			h6 {
				color: $grey-5;
			}

			.step-number {
				border: 1px solid $color-disabled;
				color: $grey-5;
			}
		}
	}

	.step-label:focus {
		outline: 3px solid var(--color-primary),
	}

	.separator {
		min-width: 40px;
		height: 2px;
		border-radius: 20px;
		background: $background-border;
	}
}
