.staff-list {
    width: 100%;
    border: none;

    .chip {
        &.active {
            background-color: $color-support-green-bg;
        }

        &.pending {
            background-color: $color-support-orange-bg;
        }
    }

    .collection__list .collection__item {
        // We have to use !important so that inline styles injected
        // by animation libraries are overriden
        height: 52.1px !important;
    }
}
