.create-study-plan-wizard {
	$requirement-border-radius: $space-xxs;

	.spinner-container {
		position: unset;
		min-height: convert-rem(200px);
		height: 50%;
		width: 100%;
	}

	&.wizard {
		grid-template-columns: 1fr;
		grid-template-rows: min-content minmax(0, 1fr);
		grid-template-areas:
			"header"
			"body";

		.wizard-body {
			grid-template-columns: 330px 1fr;
			grid-template-rows: min-content minmax(0, 1fr);
			grid-template-areas:
				"sidebar steps"
				"sidebar main";

			@include breakpoint(mobile) {
				max-width: 100vw;
				grid-template-columns: 1fr;
				grid-template-rows: max-content min-content auto;
				grid-template-areas:
				"steps"
				"sidebar"
				"main";
			}
		}

		.wizard-main {
			.steps-navigator {
				@include breakpoint(mobile) {
					position: sticky;
					bottom: 0;
				}
			}
		}
	}

	.skeleton-component {
		width: 500px;
		margin: 16px 32px;

		@include breakpoint(mobile) {
			width: calc(100% - 64px);
		}
	}

	.condition-label {
		color: $black;
		background-color: $color-secondary-bg;

		&.hidden {
			display: none;
		}
	}

	.steps {
		grid-area: steps;
		padding: $space-sm 40px;
		border-bottom: 1px solid var(--color-line, $background-border);

		@include breakpoint(mobile) {
			width: 100%;
			overflow-x: scroll;
			overflow-y: hidden;
		}
	}

	.wizard-sidebar {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;

		@include breakpoint(mobile) {
			padding: $space-xs;
		}

		.top.program-info {
			flex-basis: content;
			color: var(--color-primary-text);
			background-color: var(--color-primary);
			padding: $space-md;

			@include breakpoint(mobile) {
				padding: $space-xs;
				border-radius: $requirement-border-radius;
			}

			.chip-group {
				flex-wrap: wrap;

				.chip {
					background-color: var(--color-primary-overlay);
					min-width: unset;

					span {
						color: var(--color-primary-overlay-text);
					}
				}

			}

			.program-detail-chip {
				margin-top: 32px;

				.chip {
					background-color: var(--color-primary);
					padding: 0;
					border: 1px;
					cursor: pointer;

					span {
						display: inline-block;
						color: var(--color-primary-text);
						border: 2px solid var(--color-primary-text);
						border-radius: $requirement-border-radius;
						padding: 6px 16px;
						font-size: $text-sm;
					}
				}

				.button-chip {
					background-color: var(--color-primary);
					padding: 8px 16px;
					border-radius: $requirement-border-radius;
					font-size: $text-sm;
					font-weight: 500;
					border: 1px solid var(--color-primary-text);
					color: var(--color-primary-text);
					letter-spacing: 0.5px;
				}

				.button-chip:focus {
					outline: 3px solid black;
					
				}
			}


			> h3 {
				color: var(--color-primary-text);
				margin-bottom: $space-md;
			}

			@include breakpoint(mobile) {
				.program-info__content {
					display: flex;
					justify-content: space-between;

					.collapse-btn {
						padding: 0;

						&:after,
						&:before {
							color: $white;
							font-size: 32px;
						}
					}

					&.collapsed {
						align-items: center;

						h3 {
							font-size: 16px;
							font-weight: 600;
							margin: 0;
						}

						div > .chip-group {
							max-height: 0;
							opacity: 0;
						}
					}

					&.expanded {
						align-items: end;
					}
				}
			}
		}

		.bottom {
			flex-grow: 1;
			overflow-y: auto;
			max-height: 100%;
			background-color: $white;
			color: $font-1;
			padding: $space-sm;

			@include breakpoint(mobile) {
				padding: 0;
			}

			.menu-list {
				@include breakpoint(mobile) {
					display: none;
				}

				.menu-list.label {
					margin: 0;
				}

				.menu-list-item {
					display: flex;
					justify-content: space-between;
					align-items: center;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					min-height: convert-rem(24px);
					padding: 0px 12px;
					width: 100%;
					background: transparent;

					border-radius: $border-radius-sm;
					border: 1px solid transparent;
					margin-bottom: 12px;
					
					&.menu-list-button {
						cursor: pointer;
						border-color: $color-secondary-bg;

						&:hover {
							background-color: $color-secondary-bg;
							border-color: $color-secondary-bg;
						}
						
						&.active {
							border-color: $color-medium-emphasis;
							background-color: $color-secondary-bg;
							font-weight: 600;
							
						}
						
						&:focus {
							outline: 2px solid var(--color-primary);
						}
					}

					.menu-list-display {
						pointer-events: none;
						max-width: 100%;
						word-break: break-word;
						padding: 7px 0;
						&.icon-material-lens::before {
							position: relative;
							font-size: 10px;
							height: fit-content;
							top: -2px;
							color: var(--color-primary);
							margin-right: $space-xxs;
						}
						text-align: left;
						&.active {
							font-weight: 600;
						}
					}
					
					.menu-list-info {
						display: flex;
						gap: 8px;
						align-items: center;
						padding: 6px 0px;
					}
					
					.info-icon-button {
						color: var(--color-warning);
					}
				}
				
				.program-total {
					border-top: 1px solid $background-border;
					font-weight: 600;
					padding: $space-xxs 12px;
					
					div:nth-child(2) {
						display: flex;
						align-items: center;
						column-gap: $space-xxs;
					}
				}
			}
		}
	}

	.step-1.content {
		@include media(mobile) {
			padding: $space-xs;
		}

		.program-structure-selector {
			> h3 {
				margin-bottom: $space-xxs;
			}
			> p {
				margin-bottom: $space-md;
			}
		}

		.structure-option-description,
		.component-description {
			max-width: 700px;
			margin-bottom: 40px;
		}

		.input-group {
			label:first-of-type {
				font-family: $heading-font-family;
				color: $font-1;
				font-size: convert-rem(22px);

				font-style: normal;
				font-weight: 500;
				line-height: normal;

				@include media(xs, sm) {
					font-size: $text-md;
				}
			}

			.dropdown__container, .select_main {
				width: 423px;
				margin: $space-xxs 0px;

				.text {
					padding-right: 37px;
				}

				@include breakpoint(mobile) {
					width: 100%;
				}
			}
		}
	}

	.step-2.content {
		@include media(mobile) {
			padding: 16px;
		}

		.header-wrapper {
			h3 {
				margin-bottom: $space-xxs;
			}

			.sub-header {
				color: $grey-5;
				font-weight: lighter;
			}
		}

		.selection-actions {
			display: flex;
			flex-direction: column;

			&__row {
				margin-top: 32px;

				width: 100%;
				display: flex;
				gap: 32px;
				max-height: max-content;

				@include media(mobile) {
					gap: 24px;
				}

				.search-course-input-wrapper.input-group {
					margin: 0 auto;
					width: 100%;

					.icon-search {
						position: absolute;
						z-index: 1;
						top: 1.1rem;
						left: 1rem;
						color: $color-medium-emphasis;
					}

					input {
						padding-left: 3rem;
						margin: 0px;
					}
				}

				.filter-btn {
					height: 54px;
				}

				&.course-filters {
					width: 100%;
					display: flex;
					gap: 8px;
					align-items: center;
					background: var(--color-primary-light);
					border-radius: 16px;

					.clear-filters-btn {
						padding: 0;
						margin-right: 8px;

						&:after {
							font-size: 30px;
							color: $color-medium-emphasis;
						}
					}

					.course-filters-inputs {
						display: flex;
						flex-wrap: wrap;
						gap: 8px;
						width: 100%;
						margin: 16px 0 16px 8px;

						.input-group__dropdown {
							margin: 0;
							width: 300px;

							@include media(sm) {
								width: 200px;
							}

							.dropdown__container, .select_main {
								margin: 0;
								min-width: unset;
								background-color: white;
								border-radius: 8px;
								border: 1px solid $background-border;

								.text {
									white-space: nowrap;
									overflow-x: clip;
									text-overflow: ellipsis;
									max-width: 90%;
								}
							}
						}
					}
				}
			}
		}

		.condition-label,
		.requisite-warning-label {
			padding: 12px;
			border-left: 1px solid $background-border;
			border-right: 1px solid $background-border;
			color: $button-secondary-text;
			background-color: $color-secondary-bg;

			h6 {
				margin: 0;
			}

			&.root-level-condition {
				margin-top: 32px;
				border-radius: $space-xxs;
			}
		}

		.requisite-warning-label {
			color: var(--color-warning-dark);
			background-color: var(--color-warning-light);
		}

		.course-selection-group {
			position: relative;

			.group-header {
				padding: 12px 12px;
				border: 1px solid $background-border;
				border-radius: $requirement-border-radius $requirement-border-radius 0 0;
				background-color: $color-secondary-bg;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-top: -1px;

				&.depth-0 {
					color: var(--color-primary-text);

					> .group-header__title {
						text-transform: uppercase;
					}
				}

				&.depth-0,
				&.depth-1 {
					> .group-header__title,
					> .group-header__right .unit-count {
						font-size: 16px;
						font-weight: 510;
						letter-spacing: 0.48px;
					}
				}

				&.depth-2,
				&.depth-3 {
					> .group-header__title,
					> .group-header__right .unit-count {
						font-size: $base-font-size;
						line-height: $body-line-height;
					}
				}

				&.depth-3 {
					> .group-header__title,
					> .group-header__right .unit-count {
						font-style: italic;
					}
				}

				h6 {
					display: inline-block;
					margin: 0;
				}

				&__right {
					display: flex;
					align-items: center;
					gap: $space-xxs;

					.expand-collapse-btn {
						display: inline-block;
						padding: 0px;
						color: $color-medium-emphasis;
					}

					.rule-error {
						&:before {
							color: var(--color-warning);
						}
					}
				}
			}

			&.requirement-container {
				> .overlap-item {
					padding-bottom: 28px;
					margin-bottom: -16px;
				}
			}

			&.collapsed {
				.group-header {
					border-radius: $requirement-border-radius;
				}

				> .empty-state,
				> .course-selection-group {
					display: none;
				}

				.course-selection,
				.condition-label,
				.requisite-warning-label {
					display: none;
				}

				&.requirement-container {
					> .overlap-item {
						padding-bottom: 12px;
						margin-bottom: 0px;
					}
				}
			}

			&.top-level {
				margin-top: 32px;

				> .group-header {
					background-color: var(--color-secondary);
					border: 0;
					color: var(--color-secondary-text);

					h6,
					.expand-collapse-btn {
						color: var(--color-secondary-text);
					}

				}

				&.collapsed {
					> .group-header {
						padding: 12px 12px 12px 12px;
					}
				}
			}

			.empty-state {
				width: 100%;
				border: 1px solid $background-border;
				border-radius: 0 0 $requirement-border-radius $requirement-border-radius;
				background-color: $white;
				padding: $space-xs;

				p {
					font-weight: lighter;
					color: $color-medium-emphasis;
					margin: 16px auto;
					text-align: center;
				}
			}

			// Set border
			> .course-selection {
				.course-toggle,
				.course {
					border: 1px solid $background-border;
					border-top: none;
				}
			}

			> .course-selection:last-of-type {
				> .course-toggle {
					border-radius: 0 0 0 $requirement-border-radius;
				}

				> .course-details {
					border-radius: 0 0 $requirement-border-radius 0;
				}
			}

			// Select all but the first course-selection class and remove the top border
			> .course-selection ~ .course-selection {
				.course-toggle,
				.course-details {
					border-top: none;
				}
			}

			.course-selection {
				display: grid;
				background: $background;
				grid-template-areas: "course-toggle course-details";
				grid-template-columns: min-content minmax(0, 1fr);

				@include media(mobile) {
					grid-template-areas: "course-details course-toggle";
					grid-template-columns: minmax(0, 1fr) min-content;
				}

				.course-toggle {
					padding: 16px;
					grid-area: course-toggle;
					border-right: none;

					@include media(mobile) {
						grid-template-areas: "course-details course-toggle";
						grid-template-columns: minmax(0, 1fr) min-content;
						border-left: none;
						border-right: 1px solid $background-border;
					}
				}

				.course {
					display: grid;
					grid-area: course-details;
					grid-template-areas:
						"course-info"
						"course-warnings"
						"course-actions";
					grid-template-rows: min-content auto;

					.course-info {
						display: grid;
						grid-area: course-info;
						grid-template-areas:
							"course-name course-units";
						grid-template-columns: 1fr auto;
						grid-template-rows: minmax(0, 1fr);
						padding: convert-rem(16px);

						@include media(mobile) {
							grid-template-areas:
							"course-name"
							"course-units";
							grid-template-columns: 1fr;
						}

						.course-name {
							grid-area: course-name;
							display: flex;

							@include media(mobile) {
								padding: 16px 16px 0 16px;
							}

							p {
								margin: 0;
							}
						}

						.course-units {
							grid-area: course-units;

							display: grid;
							grid-template-areas: "tooltip-icon unit-count";
							grid-template-columns: 1fr 65px;
							justify-items: end;
							gap: 8px;

							@include media(mobile) {
								padding: 0 16px 16px 16px;
								grid-template-areas: "unit-count tooltip-icon";
								grid-template-columns: 65px 1fr;
							}

							> span {
								grid-area: tooltip-icon;
								color: $color-medium-emphasis;

								&:hover {
									cursor: pointer;
								}
							}


							p {
								grid-area: unit-count;
								width: 65px;
								margin: 0;
								font-weight: lighter;
							}
						}

						.requisite-description {
							color: $grey-5;
						}
					}

					.course-warnings {
						grid-area: course-warnings;
					}

					.course-actions {
						grid-area: course-actions;
						padding: 16px;
						border-top: 1px solid $background-border;
						display: flex;
						gap: 16px;
						align-items: center;

						@include media(mobile) {
							flex-direction: column;
						}

						.input-group {
							width: 100%;
							margin: 0;
							min-width: unset;

							.dropdown__container, .select_main {
								margin: 0;
								min-width: unset;
							}
						}

						.no-offerings-available {
							margin: 0;
							font-style: italic;
							width: 100%;
						}

						.select__option-container {
							.select__option-label {
								margin: 0;
								border-radius: 8px;
								width: fit-content;
							}
						}
					}
				}
			}
		}
	}

	.step-3.content {
		@include media(mobile) {
			padding: 16px;
		}

		section {
			padding: $space-md 0px;

			h6 {
				margin: $space-xxs 0px;
			}

			p {
				margin: 0;
			}

			& + section {
				border-top: 1px solid $background-border;
			}

			&.page-header {
				padding-top: 0;
			}

			&.program-structure {
				display: flex;
				flex-direction: column;
				gap: $space-xxs;
				h3, h6, p {
					margin: 0;
				}
			}
		}

		.requirement-check-section-header {
			margin-bottom: $space-xxs;
		}
	}

	.step-2.content,
	.step-3.content {
		.course-selection-notice {
			background-color: var(--color-warning-light);
			display: grid;
			grid-template-areas: "icon text content";
			grid-template-columns: min-content minmax(0, max-content) 1fr;
			justify-content: start;
			column-gap: 8px;

			span {
				grid-area: icon;
			}

			span,
			p {
				color: var(--color-warning-dark);
				margin: 0;
			}

			.notice-title {
				grid-area: text;
				font-weight: bold;
			}

			.notice-message {
				grid-area: content;
			}

			span.expand-btn {
				grid-area: dropdown;
				display: none;
			}

			@include media(sm) {
				grid-template-areas:
					"icon text dropdown"
					"content content content";
				grid-template-columns: min-content 1fr min-content;

				span.expand-btn {
					display: block;
				}

				#notice-message {
					grid-area: content;
					margin-top: 8px;
				}
			}
		}

		.collapsed {
			.course-selection-notice {
				display: none;
			}
		}
	}

	// step 4
	@import "./study-plan-page";
}

.course-info-modal {
	.modal__header {
		.course-code {
			font-weight: 400;
		}
	}

	.modal__message {
		.information-modal__section-group-wrapper {
			box-sizing: border-box;
			display: grid;
			grid-template-columns: 1fr 1fr;
			column-gap: 80px;

			@media only screen and (max-width: 800px) {
				grid-template-columns: 1fr;
			}

			.information-modal__section-group {
				min-width: 0%;

				.chip {
					min-width: unset;
				}

				.chip-group.vertical-list .chip {
					width: unset;
				}
			}
		}

		.information-modal__section {
			.study-period-container {
				display: flex;
				gap: 32px;

				&:first-of-type {
					margin-bottom: 8px;
				}
			}
		}

		@media only screen and (max-width: 800px) {
			.modal__action {
				margin: 0;
				justify-content: center;
			}
		}
	}
}