.program-version-status-chip.chip {
	text-transform: capitalize;
	&.draft {
		background-color: $color-support-blue-bg;
	}
	&.in-review {
		background-color: $color-support-purple-bg;
	}
	&.published {
		background-color: var(--color-success-light);
	}
	&.archived {
		background-color: $color-secondary-bg;
	}
	&.approved {
		background-color: $color-support-orange-bg;
	}
}