.select_main {
	--select-default-padding: 0.5rem;

	grid-area: input;
	font-weight: $text-regular;
	margin-top: 4px;
	color: $grey-2;

	.select__control {
		border: 1px solid $background-border;
		box-shadow: none;
		border-radius: 8px;
		background: $background;
		height: convert-rem(54px);

		&.has-errors {
			box-shadow: inset 0 0 0 .125rem var(--color-warning) !important;
		}

		&.empty-options {
			background: $background;
		}
	}

	.select__option {
		font-weight: normal;

		&.selected {
			background-color: $grey-0;
			font-weight: bold;
		}
	}

	// These are the formatOptionLabel components
	.select__option-container {
		.select__option-label {
			padding: var(--select-default-padding);
			margin: 0;
			color: $black;
		}

		.select__option-description {
			margin: 0;
			color: $color-medium-emphasis;
		}
	}

	.select-placeholder {
		padding: var(--select-default-padding);
		color: $color-medium-emphasis;
		font-size: convert-rem(16px);
	}

	.empty-option-placeholder {
	}

	.disabled-placeholder {
		color: transparent;
	}

	.select__input {
		padding: var(--select-default-padding);
		margin: 0;
	}

	.select__value-container {
		padding: 0;
	}

	.select__no-option-message {
		padding: var(--select-default-padding);
		color: $grey-8;
		margin: 0;
	}
}