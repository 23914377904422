.toggle {
	--width: 60px;
	--height: 30px;

	position: relative;
	display: inline-block;
	width: var(--width);
	min-width: var(--width);
	height: var(--height);
	border-radius: var(--height);
	cursor: pointer;
	
	.slider {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: var(--height);
		background-color: $color-secondary-bg; // off background color
		transition: all 0.3s ease-in-out;
		border: 2px solid transparent;

		&.focused {
			outline: 3px solid var(--color-primary);
		}
		
		&:before {
			content: '';
			position: absolute;
			top: 3px;
			left: 4px;
			width: 20px;
			height: 20px;
			border-radius: calc(var(--height) / 2);
			background-color: $grey-5; // off circle color
			transition: all 0.3s ease-in-out;
		}
	}

	.invisible-label {
		color: transparent;
		font-size: 0;
	}

	.labels {
		position: absolute;
		top: 5px;
		left: 0;
		width: 100%;
		height: 100%;
		font-size: 12px;
		font-family: sans-serif;
		transition: all 0.3s ease-in-out;
		overflow: hidden;

		&:before {
			content: attr(data-on);
			position: absolute;
			left: calc(var(--height) - var(--width) + 9px);
			color: $white; // on text color
			opacity: 0;
			transition: all 0.3s ease-in-out;
		}

		&:after {
			content: attr(data-off);
			position: absolute;
			right: 9px;
			color: $grey-5; // off text color
			opacity: 1;
			transition: all 0.3s ease-in-out;
		}
	}

	input {
		// cannot hide input, else it cannot be focused
		opacity: 0;

		&:checked + .slider {
			background-color: var(--color-primary-dark); // on background color

			&:before {
				background-color: $white; // on circle color
				transform: translateX(calc(var(--width) - var(--height)));
			}

		}

		&:checked ~ .labels {
			&:after {
				opacity: 0;
				transform: translateX(calc(var(--width) - var(--height)));
			}

			&:before {
				opacity: 1;
				transform: translateX(calc(var(--width) - var(--height)));
			}
		}

		&:disabled + .slider {
			background: $color-secondary-bg;
			&.checked { // use an exttra class on the slider
				background: $color-disabled-light;
			}
		}

		&:disabled ~ .labels {
			cursor: not-allowed;
		}
	}
}