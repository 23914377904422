.add-edit-program-page {
	height: 100%;
	display: grid;
	grid-template-areas: 
		"page-main"
		"page-footer";
	grid-template-columns: 1fr;
	grid-template-rows: minmax(0, auto) min-content;

	.page-main {
		padding: 32px 120px;

		.input-section {
			h5 {
				padding: 16px 0;
				border-bottom: 1px solid $background-border;
			}

			&__body {
				max-width: 600px;

				.horizontal-input-group {
					display: flex;
					gap: 16px;

					.input-group {
						width: 100%;
						margin: 0;

						.dropdown {
							margin: 0;

							.icon {
								top: 0;
								right: 0;
							}
						}
					}
				}
			}
		}
	}

	.page-footer {
		grid-area: page-footer;
		padding: 16px 40px;
		display: flex;
		justify-content: end;
		gap: 16px;
		background: var(--color-white, #FFF);

		/* Shadow - up */
		box-shadow: 0px -2px 6px 0px rgba(124, 124, 124, 0.06);
	}
}