.bulk-upload-jobs-collection {
	.collection__list {
		table {
			thead {
				th:last-child {
					width: 1%;
				}
			}

			tbody {
				.collection__item {
					td {
						&:last-child {
							display: flex;
							align-items: center;
						}

						.job-status {
							font-size: 14.5px;
							width: fit-content;
							padding: 4px 8px;
							border-radius: 8px;
							background: $background-border;
						}

						.processing-status {
							background: var(--color-warning-bg, #FFF3E0);
						}

						.ready-for-review-status {
							background: var(--color-info-bg, #E9EAF9);
						}

						.uploading-status,
						.complete-status {
							background: var(--color-success-bg, #E1FAEE);
						}

						.error-status {
							background: var(--color-danger-bg, #FFDCE5);
						}

						.btn {
							padding: 0 16px;
							font-size: 14.5px;
						}
					}
				}
			}
		}
	}
}

.bulk-upload-job-review-modal {
	width: 100%;
	max-width: 700px;

	.modal__message {
		> h3 {
			margin-bottom: 8px;
		}

		> p {
			padding-bottom: 24px;
			color: $color-medium-emphasis;
		}

		.collection__list {
			table {
				thead tr th:nth-child(2),
				.collection__item td:nth-child(2) {
					padding-left: 16px;
					padding-right: 16px;
				}

				.collection__item {
					td:last-child {
						text-wrap: initial;
					}
				}
			}
		}
	}
}
