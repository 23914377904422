.user-management-page {
	@include list-page-layout;
	padding: 40px;
	margin: 0 $space-xs $space-xs $space-xs;

	.title {
		height: convert-rem(48px);
		display: flex;
		align-items: center;
	}

	.tabs {
		margin: 0;
	}

	@mixin list-rows {
		display: flex;
		flex-direction: column;
		gap: $space-xs;

		.list-row {
			display: flex;
			gap: $space-xs;
		}
	}

	.user-count-label {
		margin-left: $space-xxs
	}

	.filtered-staff-list,
	.filtered-student-list {
		@include list-rows;

		.search-bar {
			flex: 1;
			.search-input {
				margin: 0;
				&.input-group > input {
					background-color: $background;
					margin: 0;
				}
			}
		}

		.search-actions {
			display: flex;
			flex-direction: row;
			gap: $space-xs;
			width: fit-content;
			align-items: center;
		}

		.filters {
			flex: 1;

			.filter-container {
				.filter-list {
					display: grid;
					gap: $space-xxs;
					grid-template-columns: repeat(auto-fit, minmax(300px, 3fr));

					.input-group-inline{
						margin: 0;
					}
				}
			}
		}
	}
}
