.program-version-collection {
	.empty-collection-content-wrapper {
		.empty-list {
			h6 {
				margin: 0px 0 $space-xxs 0;
			}
			p {
				margin-bottom: $space-sm;
			}
		}
	}

	.chip {
		text-transform: capitalize;
		&.draft {
			background-color: $color-support-orange-bg;
		}
		&.in-review {
			background-color: $color-support-purple-bg;
		}
		&.published {
			background-color: var(--color-success-light);
		}
		&.archived {
			background-color: $color-secondary-bg;
		}
	}
}