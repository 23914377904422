/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */

// % protected region % [Customise react-contextify here] on begin
.react-contexify {
	padding: 0;
	border-radius: 0;
	min-width: 150px;

	.react-contexify__item {
		&:hover {
			.react-contexify__item__content {
				background: none !important;
			}
		}

		.react-contexify__item__content {
			padding: 8px;
			border-bottom: 1px solid #E3E3E3;

			&:hover {
				.btn {
					color: white;
					transition: .1s;
				}
			}

			button.btn {
				background: none;
				color: #1C2321 !important;
				height: 40px;
				min-width: min-content !important;
				font-size: 1rem;
				padding: 8px;
				width: 100% !important;
				display: inline-flex;
				position: relative;
				border: none;
				justify-content: flex-start;
				align-items: center;
				white-space: nowrap;
				font-weight: 500;
				text-decoration: none;
				border-radius: 8px;
				cursor: pointer;
				transition: .2s;
				appearance: none;
				gap: 8px;
				-webkit-appearance: none;
				-moz-appearance: none;
				-webkit-user-select: none;
				-moz-user-select: none;

				&.icon-right,
				&.icon-left {
					&:before,
					&:after {
						color: #8F96A0;
						font-weight: 600;
						margin: 0 !important;
					}
				}

				a & {
					text-decoration: none;
					color: rgb(34, 34, 34);
					border-bottom: none;

					&:active,
					&:focus,
					&:visited {
						text-decoration: none;
					}
				}

				&:focus {
					outline: none;
				}

				&:active {
					transition: none;
				}

				&:hover {
					cursor: pointer;

					&.icon-right,
					&.icon-left {
						&:before,
						&:after {
							color: #1C2321 !important;
						}
					}

					@-moz-document url-prefix() {
						transition: none;
					}
				}

				&:hover,
				&:active {
					background-color: #F6F6F6 !important;
				}
			}
		}
	}
}
// % protected region % [Customise react-contextify here] end
