.register-page {
	padding: 16px;
	display: grid;
	grid-template-areas: 'form background';
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;

	@include media(xs, sm) {
		grid-template-areas: 'form';
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
	}

	.institute-background {
		grid-area: background;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		border-radius: 48px;
	}

	.register {
		display: flex;
		grid-area: form;

		@include media(sm) {
			align-self: start;
		}

		@include media(xsm) {
			width: 100%;
			max-width: 100%;
		}

		.institute-logo {
			max-width: 110px;
		}

		.register-name {
			display: flex;
			gap: 16px;

			.input-group {
				min-width: unset;
			}

			@include media(sm) {
				flex-direction: column;
				gap: 0;
				width: 100%;
			}
		}

		.btn-group {
			gap: 24px;

			.btn {
				width: 100%;
				margin: 0;
			}

			.cancel-register {
				padding: 0;

				&:hover {
					background: transparent;
					text-decoration: underline;
				}
			}
		}
	}
}

.registration-info-page {
	display: grid;
	grid-template-areas: "message";
	grid-template-rows: 1fr;
	grid-template-columns: 1fr;
	background-color: #F2F2F2;

	.registration {
		padding: 40px;
		background-color: white;
		border-radius: 24px;
		width: min-content;
		min-width: 380px;
		margin: auto;
		text-align: center;

		.icon:before {
			font-size: 40px;
			color: #007FC6;
		}

		.icon-cancel:before {
			color: var(--color-warning);
		}

		p {
			color: $color-medium-emphasis;

			&:last-child {
				margin: 0;
			}
		}

		.login-link,
		.resend-verification-link {
			width: 100%;
		}
	}
}