.filter-container {
	.content {
		background-color: $color-secondary-bg;
		padding: $space-xs $space-xxs;
		display: flex;
		gap: 8px;
		border-radius: $border-radius-sm;

		.filter-list {
			flex: 1;
			align-items: center;

			.input-group-inline {
				grid-area: unset;
			}

			.select_main {
				margin-top: 0;
			}
		}

		.close-button {
			display: relative;
			float: right;
			top: -8px;
			padding: 0;
			color: $color-medium-emphasis;
		}
	}
}