/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Navigation
-------------------------------- */
/*
Styling for basic navigation, vertical navigation and horizontal navigation.
*/
// % protected region % [Change generic nav styles here] on begin
// Removed styles to use front end styles
// % protected region % [Change generic nav styles here] end

// % protected region % [Change tabs styles here] on begin
/*Tabs*/
// PR is turned on so botwritten css could be removed
// % protected region % [Change tabs styles here] end

// % protected region % [Change tabs styles here] on begin
// PR is turned on so botwritten css could be removed
// % protected region % [Change tabs styles here] end

// % protected region % [Change breadcrumb styles here] off begin
/*Breadcrumbs*/
.breadcrumbs {
	padding-left: unset;
	display: flex;
	flex-direction: row;
	list-style: none;
	align-items: center;

	li {
		&.disabled {
			cursor: not-allowed;
		}

		&:not(:last-child) {
			margin-right: $admin-space-sm;
		}

		&:before {
			vertical-align: middle;
		}

		a {
			color: $admin-color-primary;
			text-decoration: none;
			cursor: pointer;
		}
	}
}
// % protected region % [Change breadcrumb styles here] end

/************ MOBILE NAVIGATION ***********/

// % protected region % [Change navigation mobile styles here] off begin
@include media(xs, sm, md) {

	//Horizontal mobile responsive
	.nav--horizontal {
		z-index: 10;

		ul li {
			padding: $admin-space-xs;
			font-size: $admin-text-md;
		}

		>a {
			grid-area: collapse;
			text-align: center;
			padding: $admin-space-xxs;
			width: 100%;
			z-index: 10;
			margin: 0;
			font-size: $admin-text-lg;
			visibility: visible;

			&:before {
				margin-right: 0 !important;
			}

			&:hover,
			&:active,
			&:focus {
				text-decoration: none;
				border: none;
			}
		}
	}

	.nav--horizontal.nav--expanded {
		display: grid;
		grid-template-areas: 'collapse' 'ul';
		grid-template-rows: auto 1fr;
		grid-template-columns: 1fr;
		position: absolute;
		bottom: 0;

		ul:nth-of-type(2) {
			grid-area: ul;
			display: flex;
			flex-wrap: wrap;

			// min-height: 40vh;
			max-height: convert-rem(3000px);
			height: auto;
			overflow: hidden;
			@include heightScaleIn(2s);

			li {
				width: 50%;
				border-right: none;
				border-bottom: convert-rem(4px) solid $admin-grey-0;

				&:nth-of-type(even) {
					border-left: convert-rem(2px) solid $admin-grey-0;
				}

				&:nth-of-type(odd) {
					border-right: convert-rem(2px) solid $admin-grey-0;
				}

				a {
					overflow-wrap: break-word;
					text-align: center;

					span {
						white-space: pre-wrap;
					}

					&:before {
						display: block;
						margin-bottom: $admin-space-xs;
						font-size: $admin-text-lg;
					}
				}
			}
		}

		ul:last-of-type {
			li {
				width: 100%;

				a {
					text-align: center;
					font-size: $admin-text-md;

					&:before {
						margin-right: $admin-space-sm;
					}
				}
			}
		}

		>a {
			background-color: $admin-grey-0;
		}
	}

	.nav--horizontal.nav--collapsed {
		justify-content: center;

		ul {
			max-height: 0;
			width: 0;
			overflow: hidden;
		}

		ul:nth-of-type(2) {
			@include heightScaleIn($admin-quick-animation-time);
		}
	}


	//Vertical mobile responsive
	.nav--vertical {
		position: absolute;
	}

	.nav--vertical.nav--expanded {
		height: 100%;
		width: auto;
		border-radius: 0;
		margin: 0;
		@include slideInLeft($admin-animation-time);

		>ul {
			>li {
				>.nav__sublinks {
					max-height: unset;
					max-width: 0;
					height: 100vh;
					top: 0;
					min-width: 0;

					&--visible {
						width: 100%;
						max-width: 300000px;
						@include slideInLeft($admin-animation-time);
					}
				}
			}
		}
	}

	.nav--vertical.nav--collapsed {
		@include slideOutLeft($admin-animation-time);
		background-color: transparent;
		box-shadow: none;

		ul {
			max-height: 0;
			width: 0;
			overflow: hidden;
		}

		>.nav__expand-icon {
			border: none;
			display: flex;
			align-items: center;
			justify-content: center;
			height: convert-rem(55px);
			width: convert-rem(55px);
			border-radius: 50%;
			box-shadow: convert-rem(0px) convert-rem(0px) convert-rem(5px) convert-rem(-2px);
			background-color: $admin-white;
			@include slideInLeft($admin-quick-animation-time);
		}
	}
}
// % protected region % [Change navigation mobile styles here] end

// % protected region % [Add additional navigation styles here] off begin
// % protected region % [Add additional navigation styles here] end