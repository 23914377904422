.create-program-wizard {
	grid-template-areas:
		'header'
		'main';
	grid-template-columns: 1fr;
	grid-template-rows: min-content minmax(0, 1fr);

	.wizard-header {
		background-color: #FFF;
		display: grid;
		grid-template-columns: minmax(0, 1fr) min-content min-content;
		grid-template-rows: 1fr;
		gap: $space-xs;
	}

	.wizard-main {
		grid-template-areas: "content" "steps-navigator";
		grid-template-rows: minmax(0, auto) min-content;
		grid-template-columns: 1fr;

		.wizard-content {
			padding: 40px 120px;
			overflow-y: auto;
		}
	}

	.program-version-title {
		margin: 0;
		padding: $space-xs 0;

		> .version-label {
			font-weight: 400;
		}
	}

	.create-program-details-page {
		.wizard-content > h3:first-letter {
			text-transform: uppercase;
		}

		form {
			overflow-x: visible;
			display: flex;
			flex-direction: column;
			gap: $space-xs;

			textarea {
				height: 9rem;
			}

			.input-group {
				width: 100%;
			}

			.form-section {
				&__heading {
					margin: 0;
					padding: $space-xs 0;
					border-bottom: 1px solid var(--color-line, $background-border);
				}
				&__content {
					width: convert-rem(600px);
				}
			}

			.period-config, .term-config {

				.form-section__content {
					width: 100%;
				}

				.horizontal-input-group:not(:first-child) {
					label {
						display: none;
					}
				}

				.input-group {
					&.period-input, &.term-input {
						i.dropdown.icon {
							display: none;
						}

						width: fit-content;

						> .input-group > .ui.selection.dropdown {
							width: fit-content;
						}
					}

					&.unit-input {
						max-width: convert-rem(110px);
						min-width: convert-rem(110px);
					}
				}
			}
		}
	}

	.create-program-requirements-page {
		.wizard-content > h3 {
			&:first-letter {
				text-transform: uppercase;
			}
			margin-bottom: $space-md;
		}
	}

	.create-program-structure-page {
		.edit-program-structure {
			&-name {
				padding-bottom: $space-xs;
			}
		}

		.structure-options-title:first-letter {
			text-transform: uppercase;
		}

		.program-structure-table {
			width: 100%;
			max-width: 100%;
			border-collapse: collapse;
			font-family: 'Plus Jakarta Sans', sans-serif;

			thead {
				tr > th {
					padding: 12px $space-xs;
					border-bottom: 1px solid $background-border;
					font-size: 14.5px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					color: var(--color-medium-emphasis, #8F96A0);
				}
			}

			tbody {
				tr > td {
					padding: 0 $space-xs;
					font-size: $space-xs;
					font-style: normal;
					font-weight: 400;
					line-height: 22px; /* 137.5% */
				}

				.program-structure-name {
					width: 20%;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}

				.course-count-cell,
				.edit-structure-btn-cell {
					width: 80px;
				}

				.program-structure-description {
					max-width: 200px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}

			tr {
				th, td {
					text-align: left;
				}

				th:first-child, td:first-child {
					padding-left: 0;
				}

				th:last-child, td:last-child {
					text-align: right;
					padding-right: 0;
				}
			}
		}

		.add-program-structure-btn {
			width: 100%;
			margin-top: 24px;
		}
	}

	.course-offerings-page {
		.page-header {
			margin: 0;

			.header-count-label {
				margin-left: $space-xs;
				color: $color-medium-emphasis;
			}
		}

		.search-field {
			margin: 40px 0px;
		}

		.offering-collection {
			.no-offerings .btn {
				margin-top: 24px;
			}

			td {
				padding-right: $space-xs;
			}

			.row-edit-button {
				margin: 0;
				padding: 0 0 0 0;
			}
		}

		.collection-component {
			.collection__list {
				.list__header {
					th {
						font-weight: lighter;
						font-size: $text-sm;
					}
				}

				table tbody .collection__item {
					user-select: none;

					&:hover {
						background-color: $color-secondary-bg;
						cursor: pointer;
					}

					td:nth-child(1) {
						width: $space-md;
					}

					td:nth-child(2) {
						width: 100px;
					}

					td:nth-child(3) {
						width: 200px;
					}

					td:nth-child(4) {
						width: 100px;
					}
				}
			}
		}
	}

	.horizontal-input-group {
		display: flex;
		gap: $space-xs;

		&.flex-start {
			justify-content: flex-start;
		}
	}
}