.user-detail-page {
	padding: calc(16px + 40px);
	display: flex;
	flex-direction: column;
	gap: $space-xs;

	> .action-buttons {
		display: flex;
		justify-content: space-between;

		& > span {
			display: flex;
			gap: $space-xs;
		}

		.btn {
			height: convert-rem(36px);
			padding: $space-xxxs $space-xs;

			&.edit {
				color: $grey-5;
				box-shadow: inset 0rem 0rem 0rem 0.0625rem $grey-5;
				&:hover {
					background-color: $grey-1;
				}
			}

			&.deactivate {
				color: var(--color-warning);
				box-shadow: inset 0rem 0rem 0rem 0.0625rem var(--color-warning);
				&:hover {
					background-color: var(--color-warning-light);
				}
			}
		}
	}

	.study-plans-collection-wrapper {
		background-color: $white;
		padding: $space-sm;
		border: 1px solid $background-border;
		border-radius: $border-radius-md;
	}
}

.user-details {
	background-color: $white;
	border: 1px solid $background-border;
	border-radius: $border-radius-md;
	padding: $space-sm;
	min-height: convert-rem(125px);

	.user-names {
		margin-left: convert-rem(6px);
		font-weight: $text-bold;
	}

	.labels {
		display: flex;
		flex-direction: row;
		justify-content: left;
		gap: $space-sm;

		@include media(mobile) {
			flex-direction: column;
			gap: $space-xs;
		}

		> span {
			display: flex;
			align-items: center;
			font-size: $base-font-size;
			line-height: $body-line-height;

			&:before {
				color: $color-medium-emphasis;
			}
		}
	}

	&.student .last-accessed-date {
		font-style: italic;
		font-size: $base-font-size;
		min-width: fit-content;
		margin-left: auto;
	}

	&.staff .labels > .chip.user-status {
		margin-left: auto;

		& > span {
			font-size: convert-rem(20px);
		}
	}
}