$bar-border-radius: convert-rem(100px);

@mixin bar-shape {
	height: 100%;
	position: absolute;
	border-radius: $bar-border-radius;
	transition: width 0.3s cubic-bezier(0.5, 0, 0.5, 1);
}

.stacked-progress-bar {
	padding: $space-sm $space-xs;

	.bar {
		position: relative;
		height: convert-rem(20px);
		width: 100%;

		.completed {
			@include bar-shape();
			width: 0%;
			background-color: var(--color-primary);
			z-index: 2;
		}
		.selected {
			@include bar-shape();
			width: 0%;
			background-color: var(--color-secondary);
			z-index: 1;
		}
		.required-courses {
			@include bar-shape();
			width: 100%;
			background-color: $background-border;
			z-index: 0;
		}
	}

	.label {
		margin-top: convert-rem(12px);
		display: flex;
		justify-content: space-between;

		.title {
			font-weight: $text-medium;
		}

		.key {
			display: flex;
			gap: $space-sm;

			::before {
				margin-right: $space-xxxs;
			}

			.completed::before {
				color: var(--color-primary);
			}
			.selected::before {
				color: var(--color-secondary);
			}
			.required-courses::before {
				color: $background-border;
			}
		}
	}
}