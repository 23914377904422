
.crud--CourseEntity {
	.actions {
		@include action-bar()
	}
}

.view-course-entity-page {
	display: flex;
	flex-direction: column;
	gap: 16px;

	.course-page-section {
		&--body {
			display: grid;
			gap: 40px;
			grid-template-areas: "details attributes";
			grid-template-columns: 60% 40%;
			grid-template-rows: 1fr;

			.course-detail {
				grid-area: details;

				.course-detail-item {
					display: flex;
					flex-direction: column;
					gap: 4px;
					margin-bottom: 16px;

					.item-header,
					.item-content {
						margin: 0;
					}

					.item-header {
						color: #8F96A0;
					}
				}
			}

			.course-attributes {
				grid-area: attributes;

				.course-attribute-item {
					display: flex;
					flex-direction: column;
					gap: 4px;
					margin-bottom: 16px;

					.item-header {
						margin: 0;
						color: #8F96A0;
					}

					.study-period-container {
						display: flex;
						gap: 8px;
						margin-bottom: 4px;

						.period-item {
							min-width: 100px;
						}
					}
				}
			}
		}

		&.requisites-section {
			.course-page-section--body {
				display: block;

				.no-requisites {
					width: 100%;
					display: flex;
					justify-content: center;
					color: #8F96A0;
					margin: auto;
					padding: 16px;
					width: 100%;
				}
			}
		}
	}
}

.create-edit-course-entity-page {
	padding: 0;

	.course-page-body {
		.course-page-section {
			&--body {
				max-width: 550px;
				display: flex;
				flex-direction: column;
				gap: 18px;

				.input-group {
					margin: 0;
				}

				.input-group__textarea {
					textarea {
						height: unset;
					}
				}

				.course-id-code-text-field-group {
					display: grid;
					grid-template-rows: 1fr;
					grid-template-columns: 1fr 1fr;
					gap: 16px;

					.input-group {
						width: 100%
					}
				}
			}
		}
	}
}