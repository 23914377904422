.navbar {
    margin: $space-xs $space-xs 0 $space-xs;
    display: flex;
    gap: $space-sm;
    padding: $space-xs;
    align-items: center;
    align-self: stretch;
    border-radius: $space-xs;
    background: var(--color-white, #FFF);
    box-shadow: 0 convert-rem(4px) convert-rem(24px) 0 rgba(124, 124, 124, 0.06);

    @include media(sm) {
        margin: 0;
        border-radius: 0;
    }

    .institute-logo {
        height: 41px;
        flex: 1 0 100px;
    }

    .nav-buttons {
        display: flex;
        flex-direction: row;
        gap: $space-sm;

        >.btn {
            color: $color-medium-emphasis;
            background-color: unset;
            border-radius: $border-radius-sm;
			padding: $space-xxs;
			height: convert-rem(40px);

            &:hover {
                color: var(--color-primary);
				background-color: var(--color-primary-light);
            }

            &.active {
				color: var(--color-primary);
				background-color: var(--color-primary-light);
            }
        }
    }

    .navbar-user-dropdown {
        flex: 1 0 100px;
        text-align: right;
        position: relative;

        .context-btn {
            padding: 11px $space-xs;
            border-radius: 64px;
            border: 1px solid $font-1;
            background-color: $white;

            @include custom-icon(after, expand-more);

            &:after {
                font-size: 24px;
            }
        }
        
        .react-contexify {
          position: absolute;
          left: unset !important;
          top: unset !important;
          right: 30px;
          bottom: -65px;
        }
    }
}