.edit-requirement {
	margin-bottom: 56px;

	.root-requirement-name {
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: #fff;
		background-color: #000;
		padding: 10px 16px;

		.btn, p {
			color: #fff;
			padding: 0;
			margin: 0;
		}
	}

	.edit-requirement-section {
		margin-top: 28px;

		.btn {
			width: 100%;
		}

		&__header {
			padding: 0 0 12px 0;
			border-bottom: 1px solid $background-border;
			margin-bottom: 0;
		}

		.add-sub-requirement-btn,
		.add-course-btn {
			margin-top: 16px;
		}

		&.overview-section {
			.edit-requirement-section__content {
				width: 516px;

				.dropdown__container {
					min-width: unset;
				}
			}
		}

		.error-text {
			color: var(--color-warning);
		}

		.horizontal-input-group {
			.input-group {
				width: 100%;
				max-width: unset;
			}

			.btn {
				margin-top: 18px;
				width: min-content;

				&:first-child {
					width: 100%;
				}
			}

			.btn.icon-list-alt {
				height: 56px;
			}
		}

		&.course-requirement-section {
			.horizontal-input-group {
				gap: 8px;

				.input-group {
					margin-top: 8px;
				}

				.btn {
					margin-top: 12px;
				}
			}

			.requirement-course-content {
				background: $white;
				margin-top: $space-xxs;
				padding: 24px 64px 24px 24px;
				border-radius: $space-xxs;
				box-shadow: 0 4px 24px 0 rgba(124, 124, 124, 0.08);

				.campus-wrapper {
					margin-top: $space-xs;
					padding: $space-xs;
					border-radius: 8px;
					border: 1px solid var(--color-line, $background-border);
					display: grid;
					grid-template-areas: 
						"location default-term"
						"course-offerings course-offerings";
					grid-template-columns: 1fr 1fr;
					grid-template-rows: min-content 1fr;
					gap: $space-xs;

					.location,
					.default-term{
						display: flex;
						align-items: center;
						gap: convert-rem(12px);

						> p {
							margin: 0;
							font-weight: lighter;
							font-size: convert-rem(13px);
						}
					}
					
					.location {
						grid-area: location;
					}

					.default-term {
						grid-area: default-term;
						justify-self: end;
					}

					.course-offerings-wrapper {
						grid-area: course-offerings;
					}

					.default-term {
						.input-group__dropdown {
							min-width: unset;
							width: fit-content;
							margin: 0;

							.select__control {
								height: convert-rem(32px);

								.select_main {
									.select__input,
									.select-placeholder,
									.select__dropdown-indicator {
										padding: 0 $space-xxs;
									}
								}
							}
						}
					}
				}

				.requisites-wrapper {
					color: $color-disabled-light;
					font-size: 13px;
					margin-bottom: 8px;

					.requisites-content {
						color: black;
						border-radius: 8px;
						border: 1px solid $background-border;
						padding: 16px;
						background: $white;
						font-size: 14.5px;
					}
				}
			}

			.add-new-course-btn-group {
				display: flex;
				gap: 8px;
			}
		}

		.show-discontinued-courses-toggle {
			margin-top: 16px;
			display: flex;
			align-items: center;
			gap: 16px;

			p {
				margin: 0;
			}
		}
	}
}

.course-offerings-wrapper {
	border-collapse: collapse;

	tr {
		border-bottom: 1px solid $background-border;
	}

	td {
		padding: 0;
	}

	thead {
		tr {
			th {
				font-size: 14.5px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				text-align: start;
				color: $grey-5;
				padding: $space-xxs 0;
			}
		}
	}

	tbody {
		tr td {
			padding: $space-xxs 0;
		}
	}

	.course-offering-input {
		.input-group {
			margin-top: 0;
			min-width: unset;

			&.cpv-input {
				min-width: 130px;
				width: 130px;

				input {
					margin-top: 0;
					height: convert-rem(32px);
				}
			}

			&.course-offering-disabled-input {
				display: block;
				width: min-content;

				.ui.dropdown__container.selection.disabled {
					padding: 11px 11px 6.5px 11px;
					margin-top: 0;
					min-width: 160px;
					width: 160px;
					background: $color-secondary-bg;

					.icon {
						display: none;
					}

					.text {
						border-radius: 8px;
						background: $background-border;
						padding: 4px 8px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						width: fit-content;
						max-width: 100%;
					}

					@media only screen and (max-width: 1400px) {
						min-width: 80px;
						width: 80px;
					}
				}
			}

			&.order-input,
			&.year-input {
				.ui.dropdown__container.selection.disabled {
					min-width: unset;
					width: min-content;
				}
			}
		}

		#notes-wrapper {
			input {
				margin-top: 0;
				height: convert-rem(32px);
			}
		}
	}
}