.wizard {
	height: 100%;
	width: 100%;
	overflow: hidden;

	display: grid;
	grid-template-rows: min-content minmax(0, 1fr);
	grid-template-areas:
		"header"
		"body";

	.wizard-body {
		grid-area: body;
		display: grid;
		overflow: auto;
	}

	.wizard-header {
		height: max-content;
		background: var(--color-white, #FFF);
		grid-area: header;

		display: flex;
		padding: $space-xs;
		margin: 0;
		border: none;
		justify-content: space-between;
		align-items: center;
		align-self: stretch;
		box-shadow: 0 4px 24px 0 rgba(124, 124, 124, 0.04);

		@include media(mobile) {
			margin: 0;
			border-radius: 0;
		}

		& > .left {
			display: flex;
			justify-content: left;
			align-items: center;
			align-self: stretch;
			column-gap: $space-xs;

			.institute-logo {
				height: 41px;
				display: inline-block;
			}

			.title {
				display: inline-block;
				border-left: 1px solid $color-medium-emphasis;
				padding-left: $space-xxs;
			}
		}

		& > .right {
			display: flex;
			justify-content: right;
			align-items: center;
			align-self: stretch;
			column-gap: $space-md;

			.success-label {
				display: flex;
				justify-content: right;
				align-items: center;
				align-self: stretch;
				column-gap: $space-xxs;
				color: $color-medium-emphasis;

				@include media(mobile) {
					display: none;
				}

				.status {
					width: 42px;

					&.saving:after {
						content: 'Saving';
					}

					&.saved:after {
						content: 'Saved';
					}
				}

				.icon-check-circle {
					color: var(--color-primary);
					height: $space-sm;
					width: $space-sm;
					display: inline-block;

					&:before {
						margin: 0;
						height: $space-sm;
						width: $space-sm;
					}
				}
			}
		}
	}

	.wizard-sidebar {
		grid-area: sidebar;
		color: var(--color-primary-text);
		border-right: 1px solid $background-border;

		h3 {
			color: var(--color-primary-text);
			margin-bottom: $space-md;
		}

		.chip {
			background-color: rgba(255, 255, 255, 0.2);

			span {
				color: white;
			}
		}
	}

	.wizard-main {
		grid-area: main;
		height: 100%;
		background-color: $background;
		display: grid;
		grid-template-areas:
			"steps"
			"content"
			"steps-navigator";
		grid-template-rows: min-content auto min-content;

		.steps {
			grid-area: steps;
		}

		& .content {
			grid-area: content;
			padding: 44px 64px;
			overflow-x: hidden;
			overflow-y: auto;
			max-height: 100%;
		}

		.steps-navigator {
			display: grid;
			grid-template-areas: "left center right";
			grid-template-rows: 1fr;
			grid-template-columns: min-content 1fr min-content;
			justify-items: center;
			align-items: center;
			justify-content: space-between;
			grid-area: steps-navigator;
			background: var(--color-white, #FFF);
			padding: $space-xs 40px;
			box-shadow: 0px -2px 6px 0px rgba(124, 124, 124, 0.06);

			@include media(sm) {
				padding: 16px;
			}

			& > .left {
				grid-area: left;
			}

			& > .center {
				grid-area: center;
			}

			& > .right {
				grid-area: right;
				display: flex;
				justify-content: right;
				align-items: center;
				align-self: stretch;
				column-gap: $space-xs;

				@include media(sm) {
					justify-content: space-between;
				}
			}
		}

		@include breakpoint(mobile) {
			max-width: inherit;
		}
	}
}