@use "sass:math";

.crud--InstitutionEntity {
	height: 100%;

	.collection-component,
	.institution-entity-form-crud-container {
		padding: 60px 120px 140px 120px;
	}

	.institution-entity-form-crud-container {
		background-color: $white;

		.institution-entity-form {
			background-color: $white;

			.settings-header {
				margin: 0 0 40px 0;
			}

			.spinner-container {
				position: static;
			}

			.institution-container {
				max-width: 615px;
				min-width: 600px;
				display: grid;
				grid-template-columns: 1fr;
				gap: 40px;

				.input-group {
					margin: 16px 0px;
				}

				> .institution-grid-element {
					grid-column: span 1;

					.test-colours {
						display: flex;
						gap: 30px;
					}

					.colour-input-group {
						display: flex;
						gap: 16px;

						.input-group {
							width: 100%;
						}
					}

					.upload.upload__file {
						margin-bottom: 0px;

						.file-input {
							margin-bottom: 8px;
						}

						.upload__image.preview {
							margin-bottom: 8px;
						}
					}

					.image-description {
						margin-bottom: 16px;
					}
				}
			}
		}
	}

	.actions {
		@include action-bar()
	}
}