.autosave-header {
    height: max-content;
    background: var(--color-white, #FFF);
    grid-area: header;

    display: flex;
    padding: $space-xs;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    box-shadow: 0 4px 24px 0 rgba(124, 124, 124, 0.04);

    @include media(mobile) {
        margin: 0;
        border-radius: 0;
    }

    & > .left {
        display: flex;
        justify-content: left;
        align-items: center;
        align-self: stretch;
        column-gap: $space-xs;

        .institute-logo {
            height: 41px;
            display: inline-block;
        }

        .title {
            display: inline-block;
            border-left: 1px solid $color-medium-emphasis;
            padding-left: $space-xxs;
        }
    }

    & > .right {
        display: flex;
        justify-content: right;
        align-items: center;
        align-self: stretch;
        column-gap: $space-md;
    }
}

.success-label {
    display: flex;
    justify-content: right;
    align-items: center;
    align-self: stretch;
    column-gap: $space-xxs;
    color: $color-medium-emphasis;

    @include media(mobile) {
        display: none;
    }

    .status {
        width: 42px;
    }

    .icon-check-circle {
        color: var(--color-primary);
        height: $space-sm;
        width: $space-sm;
        display: inline-block;
        &:before {
            margin: 0;
            height: $space-sm;
            width: $space-sm;
        }
    }
}