
/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Buttons
https://medium.com/codyhouse/create-your-design-system-part-5-icons-594f39cfb1b
-------------------------------- */
/*
Buttons - all styles are found here, the generic button style, accent colour styles.
*/

/* --------------------------------
Buttons
https://medium.com/codyhouse/create-your-design-system-part-5-icons-594f39cfb1b
-------------------------------- */
// % protected region % [Change standard button styles here] off begin
$btn-font-size: $admin-base-font-size !default;
$radius: 0 !default;

$btn-xs: calc(#{$btn-font-size} - 0.4rem) !default;
$btn-sm: calc(#{$btn-font-size} - 0.2rem) !default;
$btn-md: calc(#{$btn-font-size} + 0.2rem) !default;
$btn-lg: calc(#{$btn-font-size} + 0.4rem) !default;
$btn-xlg: calc(#{$btn-font-size} + 0.6rem) !default;

$btn-radius: $radius !default;


/*Standard button styles*/
a {
	&.btn {
		text-decoration: none;
	}
}

/*Universal button styles, controlling display settings, radius, width, height and other declarations. Add classes to your HTML to combine baseline styles with
/*other style modules*/

.btn {
	display: inline-flex;
	position: relative;
	border: none;
	justify-content: center;
	align-items: center;
	white-space: nowrap;
	font-weight: 500;
	text-decoration: none;
	line-height: 1;
	padding: $admin-space-xs;
	border-radius: $btn-radius;
	font-size: $btn-font-size;
	cursor: pointer;
	transition: .2s;
	@include appearance-none;
	@include fontSmooth;

	a & {
		text-decoration: none;
		color: $admin-black;
		border-bottom: none;

		&:active,
		&:focus,
		&:visited {
			text-decoration: none;
		}
	}

	&:focus {
		outline: none;
	}

	&:active {
		transition: none;
	}

	&:hover {
		cursor: pointer;
		@include transform-timing($admin-animation-time);

		@-moz-document url-prefix() {
			transition: none;
		}
	}
}
// % protected region % [Change standard button styles here] end

/*BUTTON COLORS
Each loop goes through the map $btn-colors and assigns the right colours to the right button type. 
An example to use classnames in your buttons: `btn btn--solid btn--primary`, `btn btn--outline btn--secondary`
If you have more colours in your application, append to the map such as: `btn--brand-04 : $admin-color-brand-04`
*/
// % protected region % [Change button colours styles here] on begin
//apply styling for solid and outline buttons without color classnames
// % protected region % [Change button colours styles here] end

// % protected region % [Change utility buttons styles here] on begin
// % protected region % [Change utility buttons styles here] end

// % protected region % [Change disabled button styles here] on begin
// % protected region % [Change disabled button styles here] end


// % protected region % [Change button size and groups here] on begin
// % protected region % [Change button size and groups here] end
// % protected region % [Add additional button styles here] off begin
// % protected region % [Add additional button styles here] end