.multi-factor-email-code-page {
	display: grid;
	grid-template-areas: "form";
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	padding: 16px;

	.institute-logo {
		max-width: 110px;
		margin-bottom: 24px;
	}

	h4, p {
		margin: 0 0 8px;
	}

	p {
		color: $grey-5;
	}

	.auth-code-form {
		grid-area: form;

		@include media(sm) {
			align-self: start;
		}

		@include media(xsm) {
			width: 100%;
			max-width: 100%;
		}

		.back-btn {
			padding: $space-xxxs;
			margin-bottom: $space-sm;
			color: $font-1;
			&:hover {
				background-color: $grey-1;
			}
			&::before {
				margin-right: 12px;
			}
		}

		.remember-skip-checkbox {
			margin: $space-xs 0 0 0;
			label {
				font-size: 14.5px;
				font-weight: 400;
			}
		}

		.dot-separator {
			margin: 0 auto $base-font-size auto;
			font-size: 2rem;
			&::before {
				content: '.';
			}
		}

		.resend-code {
			color: $font-1;
			width: 100%;
			display: flex;
			justify-content: center;
			margin: 0;

			&__btn {
				display: inline;
				padding: 0;
				font-family: $body-font-family;
				font-size: $base-font-size;
				line-height: $body-line-height;
				&::before {
					content: "\00a0";
				}
			}
		}

		.form-button-group {
			margin: $space-sm 0 0 0;
			.submit-btn {
				margin: 0;
			}
		}
	}
}