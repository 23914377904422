.edit-unit-conditions-form {
	&__header {
		padding: 0 0 12px 0;
		border-bottom: 1px solid $background-border;
		margin-bottom: 0;
	}

	&__content {
		max-width: 590px;

		.horizontal-input-group .btn.delete-rule-btn {
			margin-top: 38px;
		}
	}

	.add-rule-btn {
		margin-top: 16px;
		width: 100%;
	}
}