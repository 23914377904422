.requisite-editor {
    min-width: 500px;
    width: 100%;

    .page-header {
        &--title {
            margin: 0px;
        }

        &--subtitle {
            color: $color-medium-emphasis;
        }
    }

    & > .description {
        > .input-group-block {
            margin-top: 0px;
            margin-bottom: $space-xs;
        }
    }

    .req-fields {
        .logic-group {
            display: grid;
            column-gap: $space-xs;
            grid-template-columns: 72px auto;
            grid-template-rows: repeat(auto, 2);
            grid-template-areas:
                "condition requisites"
                "condition action-buttons";

            .condition {
                grid-area: condition;
                margin-bottom: $space-xxs;
                background: $color-support-purple-bg;

                color: $color-support-purple-dark;
                border-radius: $space-xxs;

                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 8px;

                .dropdown__container{
                    min-height: 0px;
                    margin: 0px;
                }

                .input-group__dropdown {
                    margin: 0px;
                }

                .input-group__dropdown,
                .dropdown__container,
                .dropdown__container.filled {
                    border: none;
                    outline: none;
                    box-shadow: none;

                    color: $color-support-purple-dark;
                    background-color: transparent;
                    .divider.text {
                        color: $color-support-purple-dark;
                    }

                    max-width: 56px;
                    width: 56px;
                    min-width: 56px;

                    .visible.menu.transition {
                        max-width: 100px;
                        width: 100px;
                        min-width: 100px;
                    }
                }

                .dropdown__container {
                    padding: 0px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }

                .dropdown.icon {
                    padding-top: 0px;
                    top: 34px;
                }
            }

            .requisites {
                grid-area: requisites;
                padding-bottom: $space-xs;
                display: grid;
                gap: $space-xs;

                .requisite-fields {
                    display: grid;
                    gap: $space-xs;
                    grid-template-columns: auto 112px 56px;
                    grid-template-areas: "course-dropdown requisite-type delete-course";

                    label {
                        display: none;
                    }

                    .input-group__dropdown,
                    .dropdown__container {
                        max-width: 100%;
                        width: 100%;
                        min-width: 0px;
                        margin: 0px;
                    }

                    > .course-dropdown {
                        width: 100%;
                        grid-area: course-dropdown;
                    }

                    > .requisite-type {
                        max-width: 100%;
                        grid-area: requisite-type;
                    }

                    > .delete-course-btn {
                        grid-area: delete-course;
                        margin-top: 0px;
                        width: 56px;
                        height: 56px;
                    }

                    &:first-of-type {
                        label {
                            display: block;
                        }

                        & > .delete-course-btn {
                            margin-top: 20px;
                        }
                    }
                }
            }

            .action-buttons {
                height: 56px;
                grid-area: action-buttons;
                display: flex;
                gap: $space-xxs;
                justify-content: space-between;

                .add-button-group {
                    width: calc(100% - 200px);
                    display: flex;
                    gap: $space-xxs;
                }

                .delete-group-btn {
                    width: 56px;
                    height: 56px;
                    background: $color-support-purple-bg;
                    color: $color-support-purple-dark;
                }
            }
        }
    }
}