﻿.add-courses-to-set-modal {
	.divider-group {
		margin: 24px 0;
		display: flex;
		align-items: center;
		gap: 24px;

		.divider {
			border-top: 1px solid $black;
			width: 100%;
		}
	}
	
	&__actions {
		flex-direction: row-reverse;
	}
}