.skeleton {
	background-image: linear-gradient(to right,rgba(0,0,0,.05) 0,rgba(0,0,0,0.08) 15%,rgba(0,0,0,.05) 30%);
	font-size: 1.5rem;
	margin: 0;

	&.background {
		background-color: $background;
		.line {
			background-color: $background;
		}
	}

	&.white {
		background-color: $white;
		.line {
			background-color: $white;
		}
	}

	background-color: $background;
}