.bulk-upload-page {
	> h3 {
		margin-bottom: 0;
	}

	> p {
		color: $color-medium-emphasis;
	}

	.section {
		.section-header {
			padding: 12px 0;
			border-bottom: 1px solid $background-border;
			margin-bottom: 8px;
		}

		.section-content {
			display: flex;
			justify-content: space-between;
			gap: 16px;
			margin-bottom: 8px;

			.btn {
				width: 100%;
			}
		}
	}

	.course-upload-section {
		margin-bottom: 32px;
	}

	.tabs {
		margin: 8px 0;
	}

	.collection-component {
		.collection__list {
			.list__header {
				th {
					font-size: 14.5px;
				}
			}
		}
	}
}