/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Login
-------------------------------- */
/*
Login, register and reset password pages
*/

// % protected region % [Change login, register and reset password styles here] on begin
.login,
.reset-password,
.register,
.auth-code-form {
	display: flex;
	align-items: flex-start;
	align-content: center;
	flex-direction: column;
	width: 70vw;
	max-width: 400px;
	margin: 0 auto;
	justify-content: center;


	@include media(xs, sm, md) {
		height: auto;
		margin: $space-sm auto;
	}

	h2 {
		margin: 0 0 $space-lg 0;

		@include media(xs, sm, md) {
			margin: 0 0 $space-md 0;
		}
	}

	.input-group {
		width: 100%;
		max-width: unset;
		margin: .5rem 0;

		.icon-look {
			position: absolute;
			right: 0;
			top: 2.6rem;

			&:hover {
				text-decoration: none;
				color: $color-secondary-bg;
				@include transform-timing($animation-time);
			}
			&:before {
				margin-right: 0;
			}
		}
	}

	.btn-group {
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: center;
		margin-top: $space-md;

		@include media(xs, sm, md) {
			margin-top: $space-xs;
		}

		@media screen and (max-width: 620px) {
			flex-direction: column;
		}

		.btn {
			display: flex;
			margin: 0;
			flex-grow: 1;

			@include media(xs, sm, md) {
				padding: $space-xs;
				margin: $space-xs auto;
			}

			@media screen and (max-width: 620px) {
				width: 100%;
				box-sizing: border-box;
			}

			&.btn--secondary {
				margin-left: $space-xl;

				@media screen and (max-width: 620px) {
					margin-left: 0;
				}
			}
		}
	}

	>p {
		margin-top: $space-md;
	}
}

.register {
	align-items: flex-start;

	a {
		text-decoration: none;
		cursor: pointer;
		min-height: convert-rem(20px);
		margin-bottom: $space-lg;
	}

	h5 {
		margin-top: 0;
	}

	.input-group {
		width: 100%;
	}
}

.login__forgot-password {
	display: flex;

	&:hover,
	&:active {
		border-bottom: none;
	}
}

.password-input-icon {
	position: absolute;
	top: 2.65rem;
	right: 1rem;
	font-size: 1.2rem;
	color: #9EA6A9;

	.password-input-icon-label {
		display: none
	}
}

.password-container {
	grid-column-start: 1;
	position: relative;
	input {
		width: 100%;
	}

	::-ms-reveal {
		display: none;
	}
}
// % protected region % [Change login, register and reset password styles here] end

// % protected region % [Add additional login, register and reset password styles here] off begin
// % protected region % [Add additional login, register and reset password styles here] end