// Shared across List, View, Create and Edit Pages
.custom-entity-crud-page {
	height: 100%;
	padding: convert-rem(40px) convert-rem(120px);

	.actions {
		@include action-bar()
	}

	.header {
		h3 {
			margin: 0;
		}

		display: flex;
		justify-content: space-between;
		margin: 0 0 16px 0;

		.btn {
			padding: 0px convert-rem(16px);
			height: convert-rem(48px);
		}
	}
}

// List page
.custom-entity-crud-page.list {
	padding: convert-rem(40px) convert-rem(120px);
	display: flex;
	flex-direction: column;
	gap: 16px;

	@include breakpoint(mobile) {
		padding: convert-rem(40px) convert-rem(16px);
	}

	.search-filter-actions {
		display: grid;
		grid-template-areas:
			"search-input actions"
			"filter-inputs filter-inputs";
		grid-template-columns: 1fr min-content;
		grid-template-rows: min-content minmax(0, 1fr);
		align-items: center;
		grid-column-gap: 20px;

		.search-input-wrapper.input-group {
			margin: 0 auto;
			width: 100%;
			grid-area: search-input;

			.icon-search {
				position: absolute;
				z-index: 1;
				top: 1.1rem;
				left: 1rem;
				color: $color-medium-emphasis;
			}

			input {
				padding-left: 3rem;
				margin: 0px;
			}
		}

		.filter-and-actions {
			grid-area: actions;
			display: flex;
			align-items: center;
			gap: 16px;
		}

		.course-filters {
			grid-area: filter-inputs;
			width: 100%;
			align-items: center;
			background: var(--color-primary-light);
			border-radius: 16px;
			margin-top: 16px;

			.course-filters-inputs {
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				gap: 8px;
				margin: 16px 8px;

				.input-group__dropdown {
					margin: 0;
					width: 300px;

					@include media(sm) {
						width: 200px;
					}

					.dropdown__container {
						margin: 0;
						min-width: unset;
						background-color: white;
						border-radius: 8px;
						border: 1px solid $background-border;

						.text {
							white-space: nowrap;
							overflow-x: clip;
							text-overflow: ellipsis;
							max-width: 90%;
						}
					}
				}
			}
		}
	}

	.collection-component .collection__list {
		.list__header {
			.select-box {
				.input-group__checkbox {
					input {
						display: none;

						&:checked {
							& + label {
								color: var(--color-primary);
							}
						}
					}

					label {
						margin: 0;
					}
				}
			}
		}

		table {
			thead,
			tbody {
				tr {
					th,
					td {
						padding: 12px;
					}
				}
			}
		}

		tbody {
			.collection__item {
				.terms-wrapper,
				.campuses-wrapper,
				.periods-wrapper {
					display: flex;
					flex-wrap: nowrap;
				}
			}
		}
	}
}

// View, Create and Edit Pages
.custom-entity-crud-page:not(.list) {
	.content {
		display: flex;
		flex-direction: column;
		gap: 16px;

		@include breakpoint(mobile) {
			padding: convert-rem(40px) convert-rem(16px);
		}

		.page-section {
			margin-bottom: 40px;

			&__header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-bottom: 16px;
				margin-bottom: 16px;
				border-bottom: 1px solid $background-border;

				h4 {
					margin: 0;
				}
			}

			&__body {
				max-width: 550px;
				display: flex;
				flex-direction: column;
				gap: 18px;

				.input-group {
					margin: 0;
				}

				.input-group__textarea {
					textarea {
						height: unset;
					}
				}

				.field-group {
					display: grid;
					grid-template-rows: 1fr;
					grid-template-columns: 1fr 1fr;
					gap: 16px;

					.input-group {
						width: 100%
					}
				}

				.detail-item {
					display: flex;
					flex-direction: column;
					gap: 4px;
					margin-bottom: 16px;

					.item-header,
					.item-content {
						margin: 0;
					}

					.item-header {
						color: #8F96A0;
					}
				}
			}
		}
	}
}
