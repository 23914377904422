.sub-item-input.horizontal-input-group {
	.sub-item-name {
		margin-top: 18px;
		height: 56px;
		color: black;
		border: 1px solid $background-border;
		background: var(--color-primary-bg, #FDFDFD);
		box-shadow: none;
		justify-content: space-between;
	}

	.delete-sub-item-btn {
		margin-top: 18px;
		width: min-content;
	}
}