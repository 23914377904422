$course-accordion-border-radius: $space-xxs;
$border-width: convert-rem(1px);

.course-accordion {
	position: relative;

	.group-header,
	.accordion-label {
		padding: 12px 16px;
	}

	.accordion-label {
		border: $border-width solid $background-border;
		border-top: none;
		background-color: $color-secondary-bg;
		font-style: italic;

		&.error {
			font-style: normal;
			border: none;
			background-color: var(--color-warning-light);
		}
	}

	.group-header {
		background-color: $color-secondary-bg;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: $border-width solid $background-border;
		border-radius: $course-accordion-border-radius $course-accordion-border-radius 0 0;
		margin-top: -$border-width;

		&.depth-0 {
			color: var(--color-primary-text);

			> .group-header__title {
				text-transform: uppercase;
			}
		}

		&.depth-0,
		&.depth-1 {
			> .group-header__title,
			> .group-header__right .unit-count {
				font-size: 16px;
				font-weight: 510;
				letter-spacing: 0.48px;
			}
		}

		&.depth-2,
		&.depth-3 {
			> .group-header__title,
			> .group-header__right .unit-count {
				font-size: $base-font-size;
				line-height: $body-line-height;
			}
		}

		&.depth-3 {
			> .group-header__title,
			> .group-header__right .unit-count {
				font-style: italic;
			}
		}

		h6 {
			display: inline-block;
			margin: 0;
		}

		&__right {
			display: flex;
			align-items: center;
			gap: $space-xxs;

			.expand-collapse-btn {
				display: inline-block;
				padding: 0px;
				color: $color-medium-emphasis;
			}

			.rule-error {
				&:before {
					color: var(--color-warning);
				}
			}
		}
	}

	// select last overlappable item sibling element and pull below element
	// up to overlap it
	.overlappable-item:not(:has(~ .overlappable-item)) {
		padding-bottom: 28px;
		margin-bottom: -16px;
	}

	&.top-level {
		margin-top: 32px;

		> .group-header {
			border: none;
			background-color: var(--color-secondary);
			color: var(--color-secondary-text);

			h6,
			.expand-collapse-btn {
				color: var(--color-secondary-text);
			}
		}
	}

	&.collapsed {
		> .group-header {
			border-radius: $course-accordion-border-radius;
		}

		.empty-state,
		.course-accordion,
		.accordion-label,
		.course-accordion-list-item,
		.condition-label,
		.empty-state {
			display: none;
			overflow: hidden;
		}

		> .overlap-item {
			padding-bottom: 12px;
			margin-bottom: 0px;
		}
	}

	.child-list {
		.empty-state {
			border-radius: 0 0 $course-accordion-border-radius $course-accordion-border-radius;
			border: $border-width solid $background-border;
			width: 100%;
			border-top: 0;
			background-color: $white;
			padding: $space-xs;

			p {
				font-weight: lighter;
				color: $grey-5;
				margin: 16px auto;
				text-align: center;
			}
		}

		// Select all but the first course-selection class
		> .course-accordion-list-item {
			border-top: $border-width solid $background-border;

			// select the last list-item
			&:last-child {
				// if list item does not contain a warning, apply radius
				// and bottom border to course item
				&:not(:has(.course-warning)) {
					.course-details {
						border-bottom: $border-width solid $background-border;
						border-radius: 0 0 $course-accordion-border-radius $course-accordion-border-radius;
					}
				}

				// if course warning is visible, ensure it has a border
				// radius
				.course-warning {
					border-radius: 0 0 $course-accordion-border-radius $course-accordion-border-radius;
				}
			}
		}

		> .course-accordion-list-item {
			background: $background;

			.course-details {
				min-height: convert-rem(26px);
				border-left: $border-width solid $background-border;
				border-right: $border-width solid $background-border;
				display: grid;
				align-items: center;
				grid-template-columns: 50% auto min-content min-content;
				grid-template-rows: min-content;
				gap: $space-xxs;
				padding: $space-xs;

				.course-name {
					grid-area: 1/1/1/2;
				}

				.course-component-list {
					grid-area: 1/2/1/3;
				}

				.additional-status {
					grid-area: 1/3/1/4;
					width: 100%;
				}

				.course-units {
					grid-area: 1/4/1/5;
				}

				@include media(mobile) {
					grid-template-columns: 1fr min-content;
					grid-template-rows: auto auto;
					
					.course-name {
						grid-area: 1/1/1/2;
					}

					.course-component-list {
						display: none;
					}

					.additional-status {
						grid-area: 2/1/3/2;
					}

					.course-units {
						grid-area: 1/2/2/3;
					}
				}

				.course-name {
					display: flex;
					flex-direction: column;
					gap: $space-xxxs;

					.requisite-description {
						color: $grey-5;
						font-size: convert-rem(14.5px);
					}

					p {
						margin: 0;
					}
				}

				.course-units {
					display: grid;
					grid-template-areas: "tooltip-icon unit-count";
					grid-template-columns: 25px 65px;
					align-items: center;
					justify-items: end;
					gap: $space-xxxs;

					@include media(mobile) {
						grid-template-areas: "tooltip-icon unit-count";
						grid-template-columns: 25px 65px;
					}

					.tooltip-icon {
						grid-area: tooltip-icon;
					}
					.unit-count {
						grid-area: unit-count;
					}

					> .tooltip-icon {
						color: $color-medium-emphasis;

						display: flex;
						flex-direction: column;
						justify-content: center;

						&:hover {
							cursor: pointer;
						}
					}

					.units {
						display: flex;
						flex-direction: column;
						justify-content: center;

						width: 65px;
						margin: 0;
						font-weight: lighter;
					}
				}

				.course-component-list {
					@include media(mobile) {
						display: none;
					}

					> p {
						font-weight: lighter;
						color: $color-medium-emphasis;
						margin: 0;
					}
				}
			}
		}
	}
}