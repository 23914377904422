.user-form-page {
	margin: 0px 16px;
	.form-fields {
		margin: 40px;
		width: 50%;

		@include breakpoint(mobile) {
			width: unset;
		}
	}

	.actions {
		@include action-bar()
	}
}