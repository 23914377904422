.completed-course-accordion {
	box-shadow: 0 0 32px 0 rgba(117, 117, 117, 0.08);
	border-radius: $space-xxs;
	overflow: hidden;
	min-height: 71px;
	transition: height 0.2s cubic-bezier(0.5, 0, 0.5, 1);

	&__header {
		padding: $space-sm $space-xs;
		background-color: transparent;
		border: none;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		&:hover {
			cursor: pointer;
		}

		h5 {
			margin: 0;
		}

		div {
			display: flex;

			p {
				margin: 0;
				font-size: 18px;
				font-style: normal;
				font-weight: 500;
				text-decoration: underline;
				letter-spacing: 0.8px;
				color: var(--color-success-dark);
			}

			.icon {
				margin-top: -2px;
				margin-bottom: -1rem;
				color: var(--color-primary);
			}
		}
	}

	&__content {
		padding: 0 $space-xs 0 $space-xs;
		display: block;
		max-height: 500px;
		overflow-y: auto;

		.completed-course {
			padding: $space-xs;
			display: grid;
			grid-template-columns: 40% 1fr fit-content(100%);
			grid-template-rows: min-content;
			justify-content: space-between;
			align-items: center;
			gap: $space-xxs;

			& + .completed-course {
				border-top: 1px solid $background-border;
			}

			.complete-credit-chip {
				margin-right: $space-xxs;
			}
			p {
				margin: 0;
				& .course-name {
					font-weight: $text-medium;
				}
			}

			.unit-count {
				display: flex;
				text-wrap: nowrap;
			}
		}
	}
}