.content.study-plan-page {
	$page-element-margin: 44px 64px;
	$page-element-margin-mobile: $space-xs;

	.spinner-container {
		position: relative;
	}

	@include media(mobile) {
		padding: $page-element-margin-mobile;
	}

	.study-plan-detail {
		margin: 0 auto;
		width: 100%;

		&__header {
			margin-bottom: $space-xxs;
		}

		&__description {
			padding-bottom: $space-sm;
			border-bottom: 1px solid $background-border;
		}

		.completed-course-accordion {
			margin-top: $space-sm;
			margin-bottom: $space-sm;
		}

		.study-plan-year ~ .study-plan-year {
			margin-top: $space-xs;
		}

		.study-plan-year {
			&__title {
				margin: $space-sm 0 $space-xs 0;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.load-reset-button {
					height: convert-rem(36px);
					padding: convert-rem(6px) convert-rem(16px);
					color: $grey-5;
					box-shadow: $grey-5 0px 0px 0px 1px inset;
					background-color: $white;

					&:after {
						height: convert-rem(36px);
						margin-top: auto;
						margin-bottom: auto;
					}
				}

				@include media(mobile) {
					display: none;
				}
			}

			.study-plan-year-row {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			.study-period {
				background-color: $background-border;
				border-radius: $space-xxs;
				box-shadow: 0 0 32px 0 rgba(117, 117, 117, 0.08);

				&:first-of-type {
					margin-top: 0;
				}

				&-header-bar {
					padding: $space-xs;
					display: flex;
					justify-content: space-between;
					align-items: center;
					gap: $space-xs;

					.period-availability-toggle {
						display: inline-block;
					}

					h5 {
						flex: 1;
						display: inline-block;
						text-transform: uppercase;
					}

					h5, p {
						margin: 0;
					}

					.course-count {
						font-size: 15px;
						margin-top: 6px;
					}
				}

				.study-term {
					background-color: $color-secondary-bg;
					padding: 12px $space-xs;
					display: flex;
					justify-content: space-between;
					align-items: center;

					h6 {
						margin: 0;
					}

					p {
						margin: 0;
						font-weight: $text-medium;
					}

					.study-load {
						display: flex;
						align-items: center;
						gap: $space-xs;

						.change-study-load-btn {
							padding: 0;
							border-radius: 100%;
							background-color: var(--color-primary);
							color: var(--color-primary-text);

							&:before {
								margin: 4px;
							}
						}
					}
				}

				.study-plan-course {
					background-color: $white;
					border: 1px solid $background-border;
					border-top-color: transparent;
					border-radius: 0;

					&.empty > p {
						text-align: center;
						padding: $space-xs;
					}

					.course-details {
						padding: $space-xs;
						display: grid;
						align-content: space-between;
						align-items: center;
						grid-template-areas: "label component-chips course-info course-units";
						grid-template-columns: 50% 1fr fit-content(convert-rem(100px)) fit-content(convert-rem(30px));
						gap: $space-xxs;

						@include media(mobile) {
							grid-template-areas:
								"label				course-info"
								"course-units		course-info"
								"component-chips	course-info";
							grid-template-columns: 1fr fit-content(convert-rem(30px));
						}

						.label {
							grid-area: label;
							margin: 0;
							font-weight: $text-regular;

							.course-name {
								font-weight: $text-medium;
							}
						}

						.component-chips {
							grid-area: component-chips;
							// width: fit-content;
						}

						.course-units {
							grid-area: course-units;
							p {
								margin: 0;
								font-weight: lighter;
								white-space: nowrap;
							}
						}

						.course-info {
							grid-area: course-info;
							height: fit-content;
							width: fit-content;
							color: $color-medium-emphasis;

							&:hover {
								cursor: pointer;
							}
						}
					}

					.requisite-description {
						padding: 8px 0 0 0;
						font-size: $text-sm;
						color: $grey-5;

						@include media(mobile) {
							display: none;
						}
					}
				}
			}
		}
	}
}

.steps-navigator {
	.export-pdf-btn {
		background-color: black;
		color: white;

		&:before,
		&:after {
			color: white;
		}
	}

	h6 {
		margin: 0;
	}
}