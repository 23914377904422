/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */

// % protected region % [Change admin top bar here] on begin
/***** Admin top bar switch between front-end and admin end *****/
$admin-top-bar-height: 48px;

.admin__top-bar {
	background-color: rgb(34, 34, 34);
	display: flex;
	justify-content: flex-end;
	top: 0;
	grid-area: adminbar;
	height: $admin-top-bar-height;

	ul {
		margin: 0.5rem 0;
		display: flex;
		align-items: center;

		li {
			list-style: none;

			.link-rm-txt-dec {
				color: rgb(255, 255, 255);
				margin-right: 2rem;
				font-family: 'Plus Jakarta Sans', sans-serif;
				text-decoration: none;
				font-weight: 200;

				&:after {
					content: "\e5c8";
					display: inline-block;
					font-family: "Material Symbols Outlined";
					color: rgb(255, 255, 255);
					margin: auto auto auto 8px;
					font-size: 24px;
					vertical-align: top;
				}

				&:hover, &:active {
					text-decoration: underline;
				}
			}
		}
	}
}
// % protected region % [Change admin top bar here] end
