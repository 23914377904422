/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Layout
-------------------------------- */
/* 
_layout.scss is the set up of your project on where things sit.
For example below, grid-template-areas are set in a way to position your navigation depending on 
the type of navigation you choose to use. If you want things positioning differently, change the grid-template-areas
*/

/* full body layout */
/* -------------------------------------------------*/
/* ----------Set your project layout here ----------*/
/* -------------------------------------------------*/


// % protected region % [Change project layout here] on begin
/*
This class is where your information is in, sits next to the nav-vertical or below the nav-horizontal.
Change if require a new layout for your body content or if you have added a new custom component
*/

.body-content {
	overflow-y: auto;
	grid-area: body;
	background-color: $background;
	color: get-contrasting-text-color($background);
	width: 100%;
	height: 100%;
}

.collection-component,
.form-submission {
	padding: $space-md;
}



// % protected region % [Change project layout here] end

// % protected region % [Add additional colours here] on begin
.collection-component,
.form-submission {
	font-family: $body-font-family;
	font-size: $base-font-size;
}

.page-wrapper {
	background: $background;
}

// default bot admin collection cleanup
[class*="crud--"] {
	height: 100%;

	> .collection-component {
		padding: convert-rem(40px) convert-rem(120px);

		.collection__menu {
			margin-top: 0px;
		}
	}
}

@mixin list-page-layout {
	display: grid;
	grid-template-columns: minmax(0, 100%);
	gap: $space-xs;
	border-radius: $border-radius-sm;

	> .row {
		display: grid;
		grid-template-columns: 1fr min-content;
		gap: $space-xs;
		align-items: center;
	}
}
// % protected region % [Add additional colours here] end