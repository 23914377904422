.study-plans-collection-wrapper {
	display: flex;
	flex-direction: column;
	gap: $space-xs;

	.study-plans-collection-title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		// height: $space-lg;

		.title {
			margin: 0;
		}

		.title .study-plan-count {
			margin-left: 16px;
			color: $grey-5;
		}
	}

	.search-input-wrapper {
		width: 100%;
		display: flex;
		gap: $space-xs;
		align-items: center;

		.search-bar {
			flex: 1;

			.search-input {
				margin: 0;

				&.input-group > input {
					background-color: $background;
					margin: 0;
				}
			}
		}
	}

	.filters .filter-container .content .filter-list {
		display: grid;
		gap: calc(0.5 * 1rem);
		grid-template-columns: repeat(auto-fit, minmax(300px, 3fr));
		& > div {
			margin: 0;
		}
	}

	#role-tooltip {
		z-index: 1000;
	}

	.study-plans-collection {
		h6 {
			margin: 8px 0;
		}

		p {
			font-weight: 200;
			white-space: normal;
			max-width: 394px;
			margin: 0 auto;
		}

		.icon-list-alt:before {
			font-size: 40px;
			height: 32px;
		}

		.no-plan .btn {
			margin-top: 24px;
		}

		.collection__list {
			.list__header {
				th {
					font-weight: lighter;
					font-size: $text-sm;
					color: $grey-5;
				}
			}

			table tbody .collection__item {
				user-select: none;

				&:hover {
					background-color: $color-secondary-bg;
					cursor: pointer;
				}

				td:nth-child(2) {
					width: 200px;
				}

				td:nth-child(3) {
					width: 250px;
				}

				td:nth-child(4) {
					width: 250px;
				}

				td:nth-child(5) {
					width: 100px;
				}

				@include media(sm) {
					td:nth-child(2) {
						width: min-content;
					}
				}

				.study-plan-user {
					&:focus {
						outline: 3px solid var(--color-primary),
					}

					span {
						display: flex;
						align-items: center;
						line-height: normal;

						&:after {
							color: $grey-10;
							font-size: 1.1rem;
						}
						svg {
							margin-left: calc(0.5 * 1rem);
						}
					}
				}
			}
		}
	}
}