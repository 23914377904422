/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Collection List Component
-------------------------------- */
/*
Mobile responsive - ideal to display grid when on mobile devices
*/

.collection-component {
	/***** Top section of collection *****/
	@import 'collection-menu-actions.scss';


	/************ LIST VIEW ************/
	@import 'collection-list-view.scss';

	/************ GRID VIEW ************/
	@import 'collection-grid-view.scss';

	// % protected region % [Change collection component styles here] on begin
	padding: 0;

	.collection__grid,
	.collection__list {
		.input-group {
			display: flex;
			min-width: unset;
		}
	}

	//pagination styles for collections if necessary
	.collection__pagination {
		display: flex;
		justify-content: flex-end;
		list-style-type: none;
		align-content: center;
		align-items: center;
		padding: 0;
		gap: 16px;

		li {
			display: flex;
			align-items: center;

			.btn {
				padding: 0;

				&:before,
				&:after {
					font-weight: 600;
					color: #007DC6;
					margin: 0;
				}

				&:hover,
				&:active {
					text-decoration: none;
					color: var(--color-primary);
				}

				&[disabled] {
					&:before,
					&:after {
						color: $color-medium-emphasis;
					}
				}
			}

			.pagination__page-number {
				margin: 0;
				width: max-content;
				display: flex;
				color: $grey-5;

				@media screen and (max-width: 620px) {
					margin: 0;
				}
			}
		}
	}

	// % protected region % [Change collection component styles here] end
	@import 'collection-mobile.scss';
}


// % protected region % [Add additional collection component styles here] off begin
// % protected region % [Add additional collection component styles here] end