.view-program-page {
	padding: 32px 120px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: $space-xs;

	.title-bar {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		h3 {
			margin: $space-xs 0;
		}
	}

	.program-crumbs {
		li, li:not(:last-child) {
			max-width: fit-content;
		}
	}

	.tabs, .tab {
		width: 100%;
	}
	.tabs {
		margin-bottom: 16px;
	}

	.crud-component {
		padding: 0px;
	}
}