.login-page {
	padding: 16px;
	display: grid;
	grid-template-areas: 'background form';
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;

	@include breakpoint(mobile) {
		grid-template-areas: 'form';
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
	}

	.institute-background {
		grid-area: background;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		border-radius: 48px;
	}

	.login {
		display: flex;
		grid-area: form;
		padding: 0px $space-sm;

		@include breakpoint(mobile) {
			align-self: start;
			width: 100%;
			max-width: 100%;
		}

		.institute-logo {
			max-width: 110px;
		}

		.link-forgotten-password {
			padding: 0;

			&:hover {
				background: transparent;
				text-decoration: underline;
			}
		}
	}
}