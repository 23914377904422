.chip-group {
	display: flex;
	gap: $space-xxs;
	flex-wrap: wrap;

	&.vertical-list {
		flex-direction: column;
	}
}

.chip {
	background-color: $color-secondary-bg;
	color: $font-1;
	padding: $space-xxxs $space-xxs;
	border-radius: $space-xxs;
	display: inline-block;
	min-width: 50px;
	user-select: none;

	&.green {
		background-color: var(--color-success-light);
	}

	span {
		margin: 0;
		font-size: convert-rem(14.5px);
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		word-break: break-all;
	}

	&.user-status {
		&.active {
            background-color: $color-support-green-bg;
        }

        &.pending {
            background-color: $color-support-orange-bg;
        }
	}
}